import { useMutation } from '@tanstack/react-query';

import { queryClient } from '../../App';
import { axiosInstance } from '../../axios/axiosInstance';
import { key as Profile } from '../APIHooks/useGetProfile';
import { key as UserKey } from '../APIHooks/userData/useGetUserData';
import { key as ConvKey } from './useGetConversationById';
import { key as InboxKey } from './useGetInbox';
import { key as LatestKey } from './useGetLatestMessages';

const url = '/api/member/messages';
export const useSendMessage = () =>
  useMutation(
    async ({ profileId, messageText, sendImage, chatId, packageId }) => {
      const formData = new FormData();
      if (profileId) formData.append('receiver_profile_id', profileId);
      if (messageText) formData.append('body', messageText);
      if (sendImage) formData.append('image', sendImage);
      if (packageId) formData.append('package_id', packageId);
      const { data } = await axiosInstance.post(url, formData);
      return data;
    },
    {
      onSuccess: (data, variables) => {
        queryClient.invalidateQueries([...ConvKey, variables.profileId]);
        queryClient.invalidateQueries([...UserKey]);
        queryClient.invalidateQueries([...InboxKey]);
        queryClient.invalidateQueries([...LatestKey]);
        queryClient.invalidateQueries([...Profile]);
      },
      onError: (error) => error.response.data.message
    }
  );
