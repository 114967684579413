import { useQuery } from '@tanstack/react-query';

import { axiosInstance } from '../../../axios/axiosInstance';

const key = ['GET_FRESHDESK_STATUS'];

export const useFreshDeskStatus = () =>
  useQuery({
    queryKey: key,
    queryFn: async () => {
      const { data } = await axiosInstance.get('api/public/freshdesk-status', {
        headers: {
          doNotAddUserTokenAuthorization: true
        }
      });
      return data;
    }
  });
