import './LogoComponent.scss';

import React from 'react';

import HEADERIMAGE from '../../assets/images/LandingPage/LocalFlirt-red-logo-white.svg';
import { showRootPage } from '../../services/navigation-service/navigation.service';
import ImageComponent from '../ImageComponent/ImageComponent';

const LogoComponent = () => {
  const redirectHome = () => {
    document.body.style.overflow = 'hidden';
    document.body.style.position = 'fixed';
    document.body.style.touchAction = 'none';

    setTimeout(() => {
      document.body.style.overflow = '';
      document.body.style.position = '';
      document.body.style.touchAction = '';

      showRootPage();
    }, 50);
    // window.location.reload();
  };

  return (
    <div className="LogoComponent" onClick={redirectHome}>
      <ImageComponent url={HEADERIMAGE} />
    </div>
  );
};

export default LogoComponent;
