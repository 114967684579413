import PropTypes from 'prop-types';
import { FiLoader } from 'react-icons/fi';

import { ArchivedConversationIcon } from '../../../../../../../../assets/images/Inbox/ArchivedConversationIcon';
import { LoadInboxIcon } from '../../../../../../../../assets/images/Inbox/LoadInboxIcon';

export const ArchivedConversation = ({ profileUsername, onClick, isLoading }) => {
  return (
    <div className="conversation__list__messages--archivedConversation">
      {!isLoading ? (
        <>
          <ArchivedConversationIcon />
          <h2 className="conversation__list__messages--archivedConversation--title">
            This conversation is archived because it is older than 30 days.
          </h2>
        </>
      ) : null}
      <div onClick={onClick} className="conversation__list__messages--archivedConversation--action">
        {isLoading ? (
          <>
            <FiLoader className="animate-spin" color="#921D26" />
            <p>Chat history is on the way…</p>
          </>
        ) : (
          <>
            <LoadInboxIcon />
            <p>Load your messages with {profileUsername}</p>
          </>
        )}
      </div>
    </div>
  );
};

ArchivedConversation.propTypes = {
  onClick: PropTypes.func.isRequired,
  profileUsername: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired
};
