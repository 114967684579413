import './MyMatches.scss';

import { FrownOutlined } from '@ant-design/icons';
import { useLocation, useNavigate } from '@reach/router';
import { Pagination, Result } from 'antd';

import Button from '../../../../../../components/Button/Button';
import LoaderComponent from '../../../../../../components/LoaderCompoment/LoaderComponent';
import { ProfileCard } from '../../../../../../components/ProfileCard/ProfileCard';
import { useGetMatches } from '../../../../../../core/APIHooks/useGetMatches';
import { useGetUserData } from '../../../../../../core/APIHooks/userData/useGetUserData';
import { useAdZoneEnabled } from '../../../../../../hooks/PopUnder/useAdZoneEnabled';
import { MEMBERS_ROUTES } from '../../members_routes';

const MyMatches = () => {
  const perPage = 30;
  const user = useGetUserData();
  const { ad_zone_settings } = user?.data?.data || {};
  const location = useLocation();
  const navigate = useNavigate();
  const query = new URLSearchParams(location.search);
  const { data, isLoading, error } = useGetMatches(query.get('page') ?? 1, perPage);

  const scrollToTop = () => {
    var elmnt = document.getElementById('scrollTop');
    elmnt.scrollIntoView({ behavior: 'smooth' });
  };

  const handleQuery = (param, value) => {
    query.set(param, value);
    navigate(`${location.pathname}?${query.toString()}`);
    scrollToTop();
  };

  const renderProfiles = () =>
    data.data.map((item) => (
      <div className={'container'} key={item.id}>
        <ProfileCard redirectFrom="matches" profile={item} key={item.id} />
      </div>
    ));

  const adZoneSettings = {
    headerMobileActive: ad_zone_settings?.adzoneHeaderMyMatchesMobileActive,
    headerWebActive: ad_zone_settings?.adzoneHeaderMyMatchesWebActive,
    footerMobileActive: ad_zone_settings?.adzoneFooterMyMatchesMobileActive,
    footerWebActive: ad_zone_settings?.adzoneFooterMyMatchesWebActive
  };

  const { isAdzoneHeaderEnabled, isAdzoneFooterEnabled } = useAdZoneEnabled(adZoneSettings);

  return (
    <>
      {isAdzoneHeaderEnabled && (
        <div
          id="header-adzone-container-my-matches"
          className="Page-AdZone Page-AdZone--Header"
        ></div>
      )}
      <div className="title"> Matches </div>
      {isLoading ? (
        <div className={'loader'}>
          <LoaderComponent />
        </div>
      ) : (
        <>
          {error ? (
            <div className={'no-result'}>
              <Result
                icon={<FrownOutlined style={{ color: '#9f0220' }} />}
                title="No Matches yet!"
                subTitle={error.response.data.message}
                extra={
                  <Button
                    text="Find a match?"
                    onClick={() =>
                      navigate(`${MEMBERS_ROUTES.MEMBERSAREA}${MEMBERS_ROUTES.MATCHGAME}`)
                    }
                  />
                }
              />
            </div>
          ) : (
            <div className={'grid-container'}>{renderProfiles()}</div>
          )}
        </>
      )}
      {isAdzoneFooterEnabled && (
        <div
          id="footer-adzone-container-my-matches"
          className="Page-AdZone Page-AdZone--Footer"
        ></div>
      )}
      {!isLoading && (
        <div className={'Pages'}>
          <Pagination
            className={'favouritePagination'}
            defaultCurrent={1}
            current={data?.meta.current_page}
            total={data?.meta.total}
            pageSize={perPage}
            onChange={(page) => handleQuery('page', page)}
            showSizeChanger={false}
            hideOnSinglePage={true}
          />
        </div>
      )}
    </>
  );
};

export default MyMatches;
