import { useMutation } from '@tanstack/react-query';
import { notification } from 'antd';
import { AxiosError } from 'axios';

import { axiosInstance } from '../../../axios/axiosInstance';
import { PurchaseType } from '../../model/enum/PurchaseType';

const key = ['FLIP_CARD_PAYMENT'];

interface CardDetails {
  card_number: string;
  expiry_date: string;
  cvv: string;
  cardholder_name: string;
  zip_code: string;
}

interface FlipCardPaymentPayload extends CardDetails {
  package_id: number;
  type: PurchaseType;
  redirect_url?: string;
  subscription_trigger?: string;
  profile_id?: number;
  last_message_id?: number;
}

export const useFlipCardPayment = () =>
  useMutation({
    mutationKey: [...key],
    mutationFn: async (payload: FlipCardPaymentPayload) => {
      const { data } = await axiosInstance.post('api/payments/checkout-flip-card', payload);
      return data;
    },
    onError: (error: AxiosError) => {
      notification.error({
        message: 'Error',
        description:
          error?.response?.data?.message ??
          error?.message ??
          'Something went wrong. Please try again.'
      });
    }
  });
