import './creditsCard.scss';

import { StarFilled } from '@ant-design/icons';
import { notification } from 'antd';
import { debounce } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';

import { queryClient } from '../../../../App';
import { Featured, Limited } from '../../../../assets/icons';
import LoaderPulse from '../../../../components/LoaderPulse/LoaderPulse';
import { cancelSpecial } from '../../../../recoil/atoms/cancelSpecial';
import { useAuthMe } from '../../../../services/authHooks/useAuthMe';
import { currencySymbol } from '../../../helpers/currency';
import { formatNumberWithDecimalPlaces } from '../../../helpers/numberHelpers';
import { CreditsPackage } from '../../../model/classes/CreditsPackage';
import { PurchaseType } from '../../../model/enum/PurchaseType';
import { key as CreditsKey } from '../../../services/packages/useGetAllPackages';
import { useCheckOutPayment } from '../../../services/payment/useCheckOutPayment';
import { CreditsCardForm } from '../CreditsCardForm/CreditsCardForm';

interface CreditsCardProps {
  packageDef: CreditsPackage;
  redirectUrl?: string;
  onSuccess?: () => void;
  trigger?: 'credits_page' | 'credits_modal';
  noBuy?: boolean;
  profileId?: number;
  lastMessageId?: number;
  starterPackage?: boolean;
  isActive: boolean;
  onFlip: () => void;
  isTestEligible?: boolean;
}

export const CreditsCardV1 = ({
  packageDef,
  redirectUrl,
  onSuccess,
  trigger,
  noBuy,
  profileId,
  lastMessageId,
  starterPackage,
  isActive,
  onFlip,
  isTestEligible = false
}: CreditsCardProps) => {
  const {
    text_color,
    footer_color,
    background_color,
    name,
    previous_number_of_messages,
    number_of_messages,
    price_per_message,
    total_price,
    featured,
    limited,
    percentage,
    currency,
    id,
    is_special_offer,
    previous_price
  } = packageDef;
  const [loading, setLoading] = useState(false);
  const [showPaymentForm, setShowPaymentForm] = useState(false);
  const [, setShouldCancelSpecial] = useRecoilState(cancelSpecial);
  const cardColor = { color: text_color };
  const { mutate: buy } = useCheckOutPayment();
  const { mutate: me, isLoading } = useAuthMe();
  const [isFlipping, setIsFlipping] = useState(false);

  useEffect(() => {
    setLoading(false);
  }, []);
  const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));
  const buyCredit = debounce(() => {
    setLoading(true);
    redirectUrl &&
      !noBuy &&
      buy(
        {
          package_id: id,
          type: PurchaseType.PURCHASE,
          redirect_url: redirectUrl,
          subscription_trigger: trigger,
          profile_id: profileId,
          last_message_id: lastMessageId
        },
        {
          onSuccess: (data, payload) => {
            setShouldCancelSpecial(true);
            queryClient.invalidateQueries(CreditsKey);
            const continueChatModalOpen =
              sessionStorage.getItem('continueChatModalOpen') === 'true';
            if (continueChatModalOpen) {
              sessionStorage.setItem('closeAfterPurchase', 'true');
            }
            if (data.redirect_url === payload.redirect_url) {
              const specialOffer = JSON.parse(
                JSON.parse(JSON.stringify(localStorage.getItem('special')))
              );
              localStorage.setItem('special', JSON.stringify({ ...specialOffer, special: false }));
              starterPackage && onSuccess && onSuccess();
              sleep(10000).then(() => {
                me();
                notification.success({ message: 'Success' });
                onSuccess && onSuccess();
              });
            } else {
              window.open(data.redirect_url, '_self');
            }
            setLoading(false);
          },
          onError: () => setLoading(false)
        }
      );
  }, 1000);
  const handleCardClick = (e: React.MouseEvent) => {
    // Prevent any actions during flip animation
    if (isFlipping) {
      e.preventDefault();
      e.stopPropagation();
      return;
    }

    // Only handle clicks on the front side
    if (e.target instanceof Element && e.target.closest('.front')) {
      if (!isTestEligible) {
        buyCredit();
      } else if (!isActive) {
        setIsFlipping(true);
        onFlip();
        // Reset flip state after animation completes
        setTimeout(() => {
          setIsFlipping(false);
        }, 1200); // Match this with your flip animation duration
      }
    }
  };

  if (isLoading) {
    return <></>;
  }

  return (
    <div
      className={`credits-card-container ${isActive ? 'flipped' : ''}`}
      onClick={handleCardClick}
    >
      <div className="flipper">
        <div className="front">
          <div className={'card-credit'}>
            {is_special_offer ? (
              <div className={'special-header'}>
                <Limited fill={'#9F0220'} className={'icon'} />
                ONE TIME SPECIAL OFFER
              </div>
            ) : null}
            <div className={`card-wrapper`} style={{ backgroundColor: background_color }}>
              {!is_special_offer ? (
                <div className={'card-header mb-2'} style={cardColor}>
                  <StarFilled /> <span>{name}</span>
                </div>
              ) : null}
              {!is_special_offer ? (
                <>
                  {limited ? <div className={'before-now'}>before</div> : null}
                  <div className={'card-amount-old '} style={cardColor}>
                    {previous_number_of_messages}
                  </div>
                </>
              ) : (
                <div className={'smallFont'}>
                  <div style={{ color: '#B3B3B3' }}>The original price</div>
                  <div className={'old-label'}>
                    {currency && currencySymbol(currency)}{' '}
                    {previous_price && formatNumberWithDecimalPlaces(previous_price, 2)}
                  </div>
                  <div>The offer price</div>
                  <div>
                    {currency && currencySymbol(currency)}{' '}
                    {formatNumberWithDecimalPlaces(total_price, 2)}
                  </div>
                </div>
              )}
              {limited ? <div className={'before-now'}>now</div> : null}
              <div
                className={'card-amount'}
                style={{ ...cardColor, backgroundColor: is_special_offer ? '#FFF0F0' : 'inherit' }}
              >
                {number_of_messages}
              </div>
              <div className={'card-subtext'}>messages</div>
              <div className={'card-price'}>
                {currency && currencySymbol(currency)}{' '}
                {formatNumberWithDecimalPlaces(price_per_message, 2)} per message
              </div>
              <div className={'card-discount'}>
                {is_special_offer ? 'Sounds amazing?' : `Save ${percentage}%`}
              </div>
              <div className={'card-buy-now'} style={{ backgroundColor: footer_color }}>
                <div className={'buy-title'}>Buy now</div>
                <div className={'buy-price'}>
                  {currency && currencySymbol(currency)}{' '}
                  {formatNumberWithDecimalPlaces(total_price, 2)}
                </div>
              </div>
              <div className={'card-icon-container'}>
                {featured ? <Featured /> : null}
                {limited ? <Limited /> : null}
              </div>
            </div>
            {loading && (
              <div className="overlay">
                <LoaderPulse />
              </div>
            )}
          </div>
        </div>
        <div className="back">
          <CreditsCardForm
            setShowPaymentForm={() => onFlip()}
            isExiting={!showPaymentForm}
            currency={currency}
            totalPrice={total_price}
            number_of_messages={number_of_messages}
            packageId={id}
            redirectUrl={redirectUrl}
            trigger={trigger}
            profileId={profileId}
            onSuccess={onSuccess}
            buttonColor={footer_color}
            lastMessageId={lastMessageId}
          />
        </div>
      </div>
    </div>
  );
};
