import { CSSProperties, FC } from 'react';

const SettingsIcon: FC<{ style?: CSSProperties }> = ({ style }) => {
  return (
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect
        x="0.25"
        y="0.25"
        width="35.5"
        height="35.5"
        rx="3.75"
        fill="#D8D5D5"
        stroke="#C7C7C7"
        strokeWidth="0.5"
      />
      <path
        d="M23.8182 10.4545C23.8182 10.0029 24.1847 9.63635 24.6363 9.63635H27.9091C28.3607 9.63635 28.7273 10.0029 28.7273 10.4545C28.7273 10.9062 28.3607 11.2727 27.9091 11.2727H24.6363C24.1847 11.2727 23.8182 10.9062 23.8182 10.4545ZM27.9091 17.2727H15.9091C15.4574 17.2727 15.0909 17.6393 15.0909 18.0909C15.0909 18.5425 15.4574 18.9091 15.9091 18.9091H27.9091C28.3607 18.9091 28.7273 18.5425 28.7273 18.0909C28.7273 17.6393 28.3607 17.2727 27.9091 17.2727ZM8.27271 18.9091H11.5454C11.9971 18.9091 12.3636 18.5425 12.3636 18.0909C12.3636 17.6393 11.9971 17.2727 11.5454 17.2727H8.27271C7.82107 17.2727 7.45453 17.6393 7.45453 18.0909C7.45453 18.5425 7.82107 18.9091 8.27271 18.9091ZM8.27271 11.2727H20.2727C20.7243 11.2727 21.0909 10.9062 21.0909 10.4545C21.0909 10.0029 20.7243 9.63635 20.2727 9.63635H8.27271C7.82107 9.63635 7.45453 10.0029 7.45453 10.4545C7.45453 10.9062 7.82107 11.2727 8.27271 11.2727ZM27.9091 24.9091H24.6363C24.1847 24.9091 23.8182 25.2756 23.8182 25.7273C23.8182 26.1789 24.1847 26.5454 24.6363 26.5454H27.9091C28.3607 26.5454 28.7273 26.1789 28.7273 25.7273C28.7273 25.2756 28.3607 24.9091 27.9091 24.9091ZM20.2727 24.9091H8.27271C7.82107 24.9091 7.45453 25.2756 7.45453 25.7273C7.45453 26.1789 7.82107 26.5454 8.27271 26.5454H20.2727C20.7243 26.5454 21.0909 26.1789 21.0909 25.7273C21.0909 25.2756 20.7243 24.9091 20.2727 24.9091Z"
        fill="black"
      />
      <circle cx="22.5" cy="10.5" r="2.5" fill="#A00220" />
      <circle cx="13.5" cy="18.5" r="2.5" fill="#A00220" />
      <circle cx="22.5" cy="25.5" r="2.5" fill="#A00220" />
      <path
        d="M22.4546 13.4546C24.1095 13.4546 25.4546 12.1095 25.4546 10.4546C25.4546 8.79965 24.1095 7.45456 22.4546 7.45456C20.7997 7.45456 19.4546 8.79965 19.4546 10.4546C19.4546 12.1095 20.7997 13.4546 22.4546 13.4546ZM22.4546 9.09092C23.2062 9.09092 23.8182 9.70292 23.8182 10.4546C23.8182 11.2062 23.2062 11.8182 22.4546 11.8182C21.7029 11.8182 21.0909 11.2062 21.0909 10.4546C21.0909 9.70292 21.7029 9.09092 22.4546 9.09092ZM13.7273 15.0909C12.0724 15.0909 10.7273 16.436 10.7273 18.0909C10.7273 19.7458 12.0724 21.0909 13.7273 21.0909C15.3822 21.0909 16.7273 19.7458 16.7273 18.0909C16.7273 16.436 15.3822 15.0909 13.7273 15.0909ZM13.7273 19.4546C12.9757 19.4546 12.3637 18.8426 12.3637 18.0909C12.3637 17.3393 12.9757 16.7273 13.7273 16.7273C14.4789 16.7273 15.0909 17.3393 15.0909 18.0909C15.0909 18.8426 14.4789 19.4546 13.7273 19.4546ZM22.4546 22.7273C20.7997 22.7273 19.4546 24.0724 19.4546 25.7273C19.4546 27.3822 20.7997 28.7273 22.4546 28.7273C24.1095 28.7273 25.4546 27.3822 25.4546 25.7273C25.4546 24.0724 24.1095 22.7273 22.4546 22.7273ZM22.4546 27.0909C21.7029 27.0909 21.0909 26.4789 21.0909 25.7273C21.0909 24.9757 21.7029 24.3637 22.4546 24.3637C23.2062 24.3637 23.8182 24.9757 23.8182 25.7273C23.8182 26.4789 23.2062 27.0909 22.4546 27.0909Z"
        fill="#A00220"
      />
    </svg>
  );
};

export default SettingsIcon;
