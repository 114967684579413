import { useEffect, useRef, useState } from 'react';

import sound from '../../assets/audio/notification.mp3';

export const useNotificationSound = () => {
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const [audioUnlocked, setAudioUnlocked] = useState(false);

  useEffect(() => {
    audioRef.current = new Audio(sound);
    audioRef.current.load();

    const unlockAudio = () => {
      if (!audioUnlocked) {
        audioRef.current = new Audio(sound);
        audioRef.current.load();
        setAudioUnlocked(true);
      }

      document.removeEventListener('click', unlockAudio);
      document.removeEventListener('touchstart', unlockAudio);
    };

    document.addEventListener('click', unlockAudio, { once: true });
    document.addEventListener('touchstart', unlockAudio, { once: true });

    return () => {
      document.removeEventListener('click', unlockAudio);
      document.removeEventListener('touchstart', unlockAudio);
    };
  }, [audioUnlocked]);

  const playNotificationSound = () => {
    if (audioUnlocked) {
      audioRef.current?.play().catch((error) => {
        console.warn('Playback prevented:', error);
      });
    }
  };

  return playNotificationSound;
};
