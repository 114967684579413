import './BrowseProfiles.scss';

import { FrownOutlined } from '@ant-design/icons';
import { useLocation, useNavigate } from '@reach/router';
import { Input, Pagination, Result, Select } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import Button from '../../../../../../components/Button/Button';
import LoaderComponent from '../../../../../../components/LoaderCompoment/LoaderComponent';
import { NotFoundModal } from '../../../../../../components/not-found-modal/NotFoundModal';
import { ProfileCard } from '../../../../../../components/ProfileCard/ProfileCard';
import { useGetProfiles } from '../../../../../../core/APIHooks/browseProfiles/useGetProfiles';
import { useAdZoneEnabled } from '../../../../../../hooks/PopUnder/useAdZoneEnabled';
import { bodyTypes, hairColors } from '../../../../../../model/constants';
import { getUserData } from '../../../../../../services/user-service/user.service';
import { MEMBERS_ROUTES } from '../../members_routes';

const BrowseProfiles = () => {
  const pageSize = 30;
  const userData = getUserData();
  const { ad_zone_settings } = userData || {};
  const navigate = useNavigate();
  const mobileView = useSelector(({ welcomeMessage }) => welcomeMessage.mobileView);
  const welcomeMessage = useSelector(({ welcomeMessage }) => welcomeMessage.welcomeMessage);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [profilesParams, setProfilesParams] = useState({
    page: searchParams.get('page') ?? '1',
    ageRange: [18, 99],
    hair: undefined,
    body: undefined
  });

  useEffect(() => {
    const checkMobileResolution = () => {
      const width = window.innerWidth;
      const isMobileDevice = width <= 768;
      setShowHideFilters(!isMobileDevice);
    };
    window.addEventListener('resize', checkMobileResolution);
    checkMobileResolution();
    return () => window.removeEventListener('resize', checkMobileResolution);
  }, []);
  const [showHideFilters, setShowHideFilters] = useState(true);
  const { data, isLoading } = useGetProfiles(
    searchParams.get('page') ? parseInt(searchParams.get('page')) : 1,
    JSON.parse(searchParams.get('ageRange')) ?? [18, 99],
    searchParams.get('hair'),
    searchParams.get('body'),
    pageSize
  );
  useEffect(() => {
    setProfilesParams({
      page: searchParams.get('page') ?? '1',
      ageRange: JSON.parse(searchParams.get('ageRange')) ?? [18, 99],
      hair: searchParams.get('hair') === 'undefined' ? undefined : searchParams.get('hair'),
      body: searchParams.get('body') === 'undefined' ? undefined : searchParams.get('body')
    });
  }, [searchParams.toString()]);
  const handlePageChange = (page) => {
    searchParams.set('page', page.toString());
    navigate(`${location.pathname}?${searchParams.toString()}`);
  };
  const checkGender = () => {
    const man = 'I am a Man';
    const woman = 'I am a Woman';
    if (userData?.profile?.gender === 'male') {
      return man;
    } else if (userData?.profile?.gender === 'female') {
      return woman;
    }
  };
  const setQuery = (val, propName) => {
    searchParams.set('page', '1');
    setProfilesParams({ ...profilesParams, [propName]: val, page: 1 });
    if (propName !== 'ageRange') {
      val && val !== 'none'
        ? searchParams.set(propName, encodeURIComponent(val))
        : searchParams.delete(propName);
      navigate(`${location.pathname}?${searchParams.toString()}`);
    } else {
      const arrayString = JSON.stringify(val);
      arrayString ? searchParams.set(propName, arrayString) : searchParams.delete(propName);
      navigate(`${location.pathname}?${searchParams.toString()}`);
    }
  };
  useEffect(() => {
    const element = document.getElementById('browse-top');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }
  }, [location]);
  const reset = () => navigate(location.pathname);

  const renderProfiles = useMemo(
    () =>
      data?.data?.map((item) => (
        <ProfileCard redirectFrom="browse" key={item.id} profile={item} disableSend />
      )),
    [data]
  );

  const adZoneSettings = {
    headerMobileActive: ad_zone_settings?.adzoneHeaderBrowseProfilesMobileActive,
    headerWebActive: ad_zone_settings?.adzoneHeaderBrowseProfilesWebActive,
    footerMobileActive: ad_zone_settings?.adzoneFooterBrowseProfilesMobileActive,
    footerWebActive: ad_zone_settings?.adzoneFooterBrowseProfilesWebActive
  };

  const { isAdzoneHeaderEnabled, isAdzoneFooterEnabled } = useAdZoneEnabled(adZoneSettings);

  return (
    <div className="MembersHome">
      {isAdzoneHeaderEnabled && (
        <div id="header-adzone-container" className="Page-AdZone Page-AdZone--Header"></div>
      )}
      {welcomeMessage && userData?.location_confirmed === 1 && (
        <h3 className="WelcomeHeader">
          Welcome back, <span>{userData?.username}</span>. <br /> Who will you flirt with today?
        </h3>
      )}
      <div className="BrowseProfiles-Wrapper">
        <div className="Browse-Profiles-Main">
          <>
            {mobileView && (
              <Button
                className="filter-btn"
                onClick={() => setShowHideFilters((prevState) => !prevState)}
                text="Filter Your Flirts &#x25BC;"
              />
            )}
            {showHideFilters && (
              <div className="Search-Parameters">
                <div className="Filters">
                  <AgeRangeFilter {...{ profilesParams, setQuery }} />
                  <div className={'profile-filter'}>
                    <label htmlFor="hair-color">Hair Color</label>
                    <Select
                      allowClear
                      style={{ width: '100%' }}
                      placeholder={'--Select--'}
                      value={profilesParams.hair}
                      options={hairColors.map((color) => {
                        return {
                          value: color,
                          label: color.charAt(0).toUpperCase() + color.slice(1)
                        };
                      })}
                      onChange={(e) => setQuery(e, 'hair')}
                    />
                  </div>
                  <div className={'profile-filter'}>
                    <label htmlFor="body-type">Body Type</label>
                    <Select
                      allowClear
                      style={{ width: '100%' }}
                      placeholder={'--Select--'}
                      value={profilesParams.body}
                      options={bodyTypes.map((body) => {
                        return { value: body, label: body.charAt(0).toUpperCase() + body.slice(1) };
                      })}
                      onChange={(e) => setQuery(e, 'body')}
                    />
                  </div>
                </div>
                <div className="Filters">
                  <div className={'profile-filter'}>
                    <label>My Location</label>
                    <Input
                      className="Location-Input"
                      disabled
                      value={`Closest to  ${userData?.profile?.city}`}
                    />
                  </div>
                  <div className={'profile-filter'}>
                    <label>My Gender </label>
                    <Input className="Location-Input" disabled value={checkGender()} />
                  </div>
                </div>
              </div>
            )}
            <div id={'browse-top'} />
          </>
          {isLoading ? (
            <div className={'loader'}>
              <LoaderComponent />
            </div>
          ) : data?.data?.length > 0 ? (
            <div className={'grid-container'}>{renderProfiles}</div>
          ) : (
            <Result
              icon={<FrownOutlined style={{ color: '#9f0220' }} />}
              title="No profiles matching criteria!"
              subTitle="Try adjusting search filters"
              extra={<Button text="Reset filters?" onClick={reset} />}
            />
          )}
        </div>
        {isAdzoneFooterEnabled && (
          <div id="footer-adzone-container" className="Page-AdZone Page-AdZone--Footer"></div>
        )}
        <div className="Pages">
          <Pagination
            className={'pagination'}
            current={data?.meta.current_page}
            defaultCurrent={1}
            total={data?.meta.total}
            onChange={handlePageChange}
            pageSize={pageSize}
            showSizeChanger={false}
          />
        </div>
      </div>
      <NotFoundModal
        open={searchParams.get('error') === 'profile-not-found'}
        onCancel={() => {
          searchParams.delete('error');
          navigate(MEMBERS_ROUTES.MEMBERSAREA);
        }}
      />
    </div>
  );
};

const AgeRangeFilter = ({ profilesParams, setQuery }) => {
  const { ageRange } = profilesParams;

  const getFromOptions = () => {
    return generateNumbers(18, Number(ageRange[1] ?? 99))
      .slice(0, -1)
      .map((age) => ({
        value: age,
        label: age,
        placeholder: `from ${age}`
      }));
  };

  const getToOptions = () => {
    return generateNumbers(Number(ageRange[0] ?? 18), 99)
      .slice(1)
      .map((age) => ({
        value: age,
        label: age,
        placeholder: `to ${age}`
      }));
  };

  const generateNumbers = (start, end) => {
    return Array.from({ length: end - start + 1 }, (_, index) => start + index);
  };

  return (
    <div className="profile-filter">
      <label>Age Range</label>
      {ageRange && (
        <div className="age-range-filter">
          <Select
            allowClear
            style={{ width: '100%' }}
            placeholder="From 18"
            optionLabelProp="placeholder"
            value={ageRange[0]}
            options={getFromOptions()}
            onChange={(e) => setQuery([e, ageRange[1]], 'ageRange')}
          />
          <Select
            allowClear
            style={{ width: '100%' }}
            placeholder="To 99"
            optionLabelProp="placeholder"
            value={ageRange[1]}
            options={getToOptions()}
            onChange={(e) => setQuery([ageRange[0], e], 'ageRange')}
          />
        </div>
      )}
    </div>
  );
};

export default BrowseProfiles;
