import PropTypes from 'prop-types';
import { FiLoader } from 'react-icons/fi';

import { LoadInboxIcon } from '../../../../../../../../assets/images/Inbox/LoadInboxIcon';
import { LoadInboxSuccessIcon } from '../../../../../../../../assets/images/Inbox/LoadInboxSuccessIcon';
import {
  SessionStorage,
  SessionStorageItems
} from '../../../../../../../../V2/helpers/SessionStorage';
import { ContainsArchivedConversationsEnum } from '../../../../../../../../V2/model/enum/ContainsArchivedConversationsEnum.enum';

export const ArchivedConversations = ({ onClick }) => {
  const statusContent = {
    [ContainsArchivedConversationsEnum.COMPLETED]: {
      icon: <LoadInboxSuccessIcon color="#1CB23D" />,
      text: 'Completed'
    },
    [ContainsArchivedConversationsEnum.RESTORING]: {
      icon: <FiLoader className="animate-spin" color="#921D26" />,
      text: 'Chat history is on the way…'
    },
    [ContainsArchivedConversationsEnum.YES]: {
      icon: <LoadInboxIcon />,
      text: 'Click here to load older messages'
    },
    [ContainsArchivedConversationsEnum.NO]: {
      icon: null,
      text: ''
    }
  };

  const status =
    SessionStorage.getItem(SessionStorageItems.ConversationRestoreStatus) ??
    ContainsArchivedConversationsEnum.NO;

  const { icon, text } = statusContent[status] || {
    icon: <LoadInboxIcon />,
    text: 'Click here to load older messages'
  };

  return (
    <>
      {icon && text && (
        <div className="older_messages_wrapper" onClick={onClick}>
          {icon}
          <h2 className="older_messages_wrapper--title">{text}</h2>
        </div>
      )}
    </>
  );
};

ArchivedConversations.propTypes = {
  onClick: PropTypes.func.isRequired
};
