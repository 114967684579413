import './MembersArea.scss';

import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSetRecoilState } from 'recoil';

import ChangePassword from '../../../../../components/ChangePassword/ChangePassword';
import LocationModalNew from '../../../../../components/LocationModalNew/LocationModalNew';
import ModalDialog from '../../../../../components/modals/ModalDialog/ModalDialog';
import PasswordResetConfirmationMessage from '../../../../../components/PasswordResetConfirmationMessage/PasswordResetConfirmationMessage';
import { userData as userDataNew } from '../../../../../recoil/atoms/userDataAtom';
import {
  setPaginationData,
  setProfiles,
  setProfilesLoading
} from '../../../../../redux/actions/profiles';
import { authMe, autoLogIn } from '../../../../../services/auth-service/auth.service';
import {
  showMembersAreaPage,
  showRootPage
} from '../../../../../services/navigation-service/navigation.service';
import { getProfiles } from '../../../../../services/profile-service/profile.service';
import {
  getToken,
  hasTokenExpired,
  saveToken
} from '../../../../../services/token-service/token.service';
import { getUserData } from '../../../../../services/user-service/user.service';
import BrowseProfiles from '../Pages/BrowseProfiles/BrowseProfiles';

const MembersArea = ({ sideBar, visitors, setVisitors }) => {
  const resetPassword = useRef(null);
  const resetPasswordConfirmMessage = useRef(null);
  const userData = getUserData();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(userData?.location_confirmed === 0 ? true : false);
  const queryString = window.location.search;
  const setUserData = useSetRecoilState(userDataNew);

  const urlParams = new URLSearchParams(queryString);
  const verified = urlParams.get('verified');

  useEffect(() => {
    if (verified) {
      const idData = urlParams.get('id');
      const hashData = urlParams.get('hash');
      const source = urlParams.get('source');

      if (!hashData) {
        return;
      }

      autoLogIn(idData, hashData, source)
        .then((res) => {
          saveToken(res.data.access_token, res.data.expires_in);
          localStorage.setItem('userData', JSON.stringify(res.data.user));
          setUserData(JSON.stringify(res.data.user));
          authMe(res.data.access_token).then(showMembersAreaPage()).then(getUsersProfiles());
        })
        .catch((err) => console.log(err));
    } else if (!getToken() || hasTokenExpired()) {
      showRootPage();
    }
  }, []);

  const getUsersProfiles = useCallback(async () => {
    dispatch(setProfilesLoading(true));
    try {
      const response = await getProfiles();

      const paginationData = response.data.data;
      dispatch(setProfiles(response.data.data.data));
      dispatch(setPaginationData(paginationData));
    } catch (e) {
      console.log(e);
    }
  }, [dispatch]);

  useEffect(() => {
    getUsersProfiles();
  }, [getUsersProfiles]);

  const closeModal = (ref) => {
    ref.current.closeModal();
  };

  const openModal = (ref) => {
    ref.current.openModal();
  };

  return (
    <div className="MembersArea">
      <BrowseProfiles sideBar={sideBar} />
      <ModalDialog ref={resetPassword}>
        <ChangePassword
          closeModal={closeModal}
          token={getToken()}
          modalRef={resetPassword}
          userData={userData}
          openModal={openModal}
          successMessageRef={resetPasswordConfirmMessage}
        />
      </ModalDialog>
      <ModalDialog ref={resetPasswordConfirmMessage} hideLogo={true}>
        <PasswordResetConfirmationMessage
          closeModal={closeModal}
          modalRef={resetPasswordConfirmMessage}
        />
      </ModalDialog>
      <LocationModalNew openModal={open} setOpen={setOpen} />
    </div>
  );
};

export default MembersArea;
