import './MatchGame.scss';

import { useNavigate } from '@reach/router';
import React, { useState } from 'react';

import { queryClient } from '../../../../../../App';
import { Settings } from '../../../../../../assets/icons';
import { CircleX, Eye, Fire, Loader, Placeholder } from '../../../../../../assets/images/MatchGame';
import NoMatches from '../../../../../../assets/images/no_matches.svg';
import Button from '../../../../../../components/Button/Button';
import ImageComponent from '../../../../../../components/ImageComponent/ImageComponent';
import NewProfileBadge from '../../../../../../components/NewProfileBadge/NewProfileBadge';
import { useGetUserData } from '../../../../../../core/APIHooks/userData/useGetUserData';
import { useAdZoneEnabled } from '../../../../../../hooks/PopUnder/useAdZoneEnabled';
import { key as MatchKey, useGetMatch } from '../../../../../../V2/services/matchGame/useGetMatch';
import { useLikeMatchProfile } from '../../../../../../V2/services/matchGame/useLikeMatchProfile';
import { MEMBERS_ROUTES } from '../../members_routes';
import { MatchModal } from './MatchModal';
import { SettingsModal } from './SettingsModal';

const MatchGame = () => {
  const navigate = useNavigate();
  const [isMatch, setIsMatch] = useState(false);
  const [isSettings, setIsSettings] = useState(false);
  const [filter, setFilter] = useState({});
  const invalidate = () => queryClient.invalidateQueries([...MatchKey]);
  /*** new API ***/
  const { data, isLoading, isFetching } = useGetMatch({ filter });
  const { mutate, isLoading: liking } = useLikeMatchProfile();

  const { data: user } = useGetUserData();
  const { ad_zone_settings } = user?.data || {};

  const yearsOld = new Date().getFullYear() - data?.birthday?.slice(0, 4);
  const closeMatchModal = () => {
    invalidate().then(() => setIsMatch(false));
  };

  const continuePlaying = () => {
    closeMatchModal();
    invalidate();
  };

  const viewProfile = () => {
    const url =
      window.location.origin + '/members-area/member-profile/' + data.id + '?view_from=match_game';
    window.open(url, '_blank');
  };

  const adZoneSettings = {
    headerMobileActive: ad_zone_settings?.adzoneHeaderMatchGameMobileActive,
    headerWebActive: ad_zone_settings?.adzoneHeaderMatchGameWebActive,
    footerMobileActive: ad_zone_settings?.adzoneFooterMatchGameMobileActive,
    footerWebActive: ad_zone_settings?.adzoneFooterMatchGameWebActive
  };

  const { isAdzoneHeaderEnabled, isAdzoneFooterEnabled } = useAdZoneEnabled(adZoneSettings);

  return (
    <div className="MatchGame">
      {isAdzoneHeaderEnabled && (
        <div
          id="header-adzone-container-match-game"
          className="Page-AdZone Page-AdZone--Header"
        ></div>
      )}
      <div className="MatchGameTitleWrapper">
        <button className="MatchGameSubtitle" onClick={() => setIsSettings(true)}>
          <Settings />
        </button>
      </div>
      <div
        className="PhotoContainer"
        key={(!isLoading && !isFetching) || liking ? data?.id : Math.random()}
      >
        <div className="PhotoContainerBefore"></div>
        <div className="PhotoContainerBefore1"></div>
        {!isLoading && !isFetching && !liking && data && (
          <>
            {!!data?.is_new && <NewProfileBadge />}
            <div className="GalleryProfileContainer" onClick={viewProfile}>
              <div className="ViewProfileWrapper">
                <img className="ViewProfileIcon eyeIcon" src={Eye} alt={'eye'} />
                <p className="ViewProfileText">VIEW PROFILE</p>
              </div>
            </div>
          </>
        )}
        {isLoading || isFetching || liking ? (
          <div className="loadingContainer">
            <img src={Loader} alt={'loader'} className={'loader'} />
          </div>
        ) : data ? (
          data?.public_avatar_url ? (
            <img
              key={data.public_avatar_url}
              src={data.public_avatar_url}
              className="ImageShadow"
              alt={data.username}
            />
          ) : (
            <ImageComponent url={Placeholder} imgClassName="ImageShadow" />
          )
        ) : (
          <div className="NoProfile">
            <img className="NoMatchesImage" src={NoMatches} />
            <span>No more matches available at this time. Don't give up!</span>
            <Button
              onClick={() => navigate(MEMBERS_ROUTES.BROWSEPROFILES)}
              text="EXPLORE NEW PROFILES NOW"
            />
          </div>
        )}
        {!isLoading && !isFetching && !liking && data && (
          <>
            <p className="userName">
              {data?.username}, {yearsOld}
            </p>
          </>
        )}
      </div>
      <div className="IconContainer">
        <img
          className={`MatchIcon ${isFetching || liking || isLoading || !data ? 'disabled' : ''}`}
          src={CircleX}
          onClick={() => {
            mutate({ id: data.id, action: 'dislike' }, { onSuccess: () => invalidate() });
          }}
          alt={'dislike'}
        />
        <img
          className={`MatchIcon ${isFetching || liking || isLoading || !data ? 'disabled' : ''}`}
          src={Fire}
          onClick={() => {
            mutate(
              { id: data.id, action: 'like' },
              {
                onSuccess: (res) => {
                  res.match ? setIsMatch(true) : invalidate();
                }
              }
            );
          }}
          alt={'like'}
        />
      </div>
      {isAdzoneFooterEnabled && (
        <div
          id="footer-adzone-container-match-game"
          className="Page-AdZone Page-AdZone--Footer"
        ></div>
      )}
      <MatchModal
        open={isMatch}
        onClose={closeMatchModal}
        data={data}
        keepPlaying={continuePlaying}
      />
      <SettingsModal
        open={isSettings}
        onClose={() => setIsSettings(false)}
        onFilterSet={(e) => setFilter(e)}
      />
    </div>
  );
};
export default MatchGame;
