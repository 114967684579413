import { notification } from 'antd';
import { Picker } from 'emoji-mart';
import React, { useEffect, useRef, useState } from 'react';
import { AiOutlineCamera, AiOutlineLoading3Quarters } from 'react-icons/ai';
import { GrEmoji } from 'react-icons/gr';
import { ImAttachment } from 'react-icons/im';
import { IoIosPaperPlane } from 'react-icons/io';
import { useRecoilState } from 'recoil';
import { v4 } from 'uuid';

import { queryClient } from '../../../../../../../../App';
import { useSendMessage } from '../../../../../../../../core/messages/useSendMessage';
import { failedMessages } from '../../../../../../../../recoil/atoms/failedMessagesAtom';
import { queueMessage } from '../../../../../../../../recoil/atoms/queueMessageAtom';
import { ContinueChattingV1 } from '../../../../../../../../V2/components/PaymentExperiments/ContinueChattingV1';
import { ContinueChattingV2 } from '../../../../../../../../V2/components/PaymentExperiments/ContinueChattingV2';
import { SplitTest } from '../../../../../../../../V2/model/enum/SplitTest.enum';
import { useTrackUserOpenPaymentModal } from '../../../../../../../../V2/services/analytics/useTrackUserOpenPaymentModal';

const CONTINUE_CHATTING_VARIANTS = {
  [SplitTest.SHOW_CONTINUE_CHATTING_V1]: ContinueChattingV1,
  [SplitTest.SHOW_CONTINUE_CHATTING_V2]: ContinueChattingV2
};

export const MessageArea = ({
  isMobile,
  profileId,
  convId,
  openBuy,
  starter,
  disableSendButton,
  userEligibleAutomaticPayment,
  openCreditsModal,
  hasCredits,
  lastEligibleTransaction,
  userHasCreditCardRemoved,
  packageId
}) => {
  const [failed, setFailed] = useRecoilState(failedMessages);
  const [queue, setQueue] = useRecoilState(queueMessage);
  const { mutate, isLoading } = useSendMessage();
  const { mutate: trackUserOpenPaymentModal } = useTrackUserOpenPaymentModal();
  const [messageText, setMessageText] = useState('');
  const [isEmoji, setIsEmoji] = useState(false);
  const [sendImage, setSendImage] = useState();
  const [previewImage, setPreviewImage] = useState();
  const texInput = useRef(null);
  const uploadImage = useRef(null);
  const uploadPhoto = useRef(null);
  const [isContinueChattingModalOpen, setIsContinueChattingModalOpen] = useState(false);
  const [isFromModal, setIsFromModal] = useState(false);
  const ContinueChatComponent = CONTINUE_CHATTING_VARIANTS[userEligibleAutomaticPayment];

  const continueChatting =
    userEligibleAutomaticPayment &&
    (messageText?.length > 0 || previewImage) &&
    !hasCredits &&
    lastEligibleTransaction &&
    !userHasCreditCardRemoved;

  const uploadImageToChat = () => {
    uploadImage.current.click();
  };
  const takePhoto = () => {
    uploadPhoto.current.click();
  };
  useEffect(() => {
    if (!starter && texInput.current) {
      texInput.current.value = '';
    }
    setMessageText(starter);
  }, [starter]);

  const resetTextAreaHeight = () => {
    if (texInput.current) {
      texInput.current.style.height = 'auto';
    }
  };

  const handleSend = (selectedPackageId = packageId) => {
    if (continueChatting && !isFromModal) {
      setIsFromModal(true);
      setIsContinueChattingModalOpen(true);
      return;
    }
    setIsFromModal(false);

    if (queue[profileId])
      setQueue({
        ...queue,
        [profileId]: [
          ...queue[profileId],
          {
            attachments: sendImage ? [URL.createObjectURL(sendImage)] : undefined,
            body: messageText,
            created_at: Date.now(),
            failed: false
          }
        ]
      });
    else
      setQueue({
        ...queue,
        [profileId]: [
          {
            attachments: [sendImage],
            body: messageText,
            created_at: Date.now(),
            failed: true
          }
        ]
      });
    texInput.current.value = '';
    mutate(
      {
        profileId,
        messageText,
        sendImage,
        chatId: convId,
        packageId: selectedPackageId
      },
      {
        onSuccess: (response) => {
          setMessageText('');
          setSendImage(undefined);
          setPreviewImage(undefined);
          texInput.current.value = '';
          resetTextAreaHeight();
        },
        onError: (e, data) => {
          if (e?.response?.status >= 500 || e?.response === undefined) {
            setQueue({});
            notification.error({ message: 'Sending message has failed.' });
            queryClient.invalidateQueries('ME');
            setIsContinueChattingModalOpen(false);
          }
          if (e.response.data.errors?.image?.length) {
            setSendImage(undefined);
            setPreviewImage(undefined);
            setQueue({
              ...queue,
              [profileId]: []
            });
            notification.error({ message: e.response.data.errors.image[0] });
            queryClient.invalidateQueries('ME');
            setIsContinueChattingModalOpen(false);
          }

          if (e.response.data.message === 'No credits left') {
            trackUserOpenPaymentModal();
            setMessageText('');
            setSendImage(undefined);
            setPreviewImage(undefined);
            texInput.current.value = '';
            resetTextAreaHeight();
            openBuy();
            setQueue({
              ...queue,
              [profileId]: []
            });
            if (failed[profileId])
              setFailed({
                ...failed,
                [profileId]: [
                  ...failed[profileId],
                  {
                    ...data,
                    id: v4(),
                    attachments: [data.sendImage],
                    body: data.messageText,
                    created_at: Date.now(),
                    failed: true
                  }
                ]
              });
            else
              setFailed({
                ...failed,
                [profileId]: [
                  {
                    ...data,
                    id: v4(),
                    attachments: [data.sendImage],
                    body: data.messageText,
                    created_at: Date.now(),
                    failed: true
                  }
                ]
              });
          }
        },
        onSettled: () => {
          setIsContinueChattingModalOpen(false);
        }
      }
    );
  };
  const resizeTextArea = (e) => {
    const regex = /^(?:\D*\d){0,2}\D*$/; // Allow any characters before up to 2 digits
    if (regex.test(e.target.value)) {
      setMessageText(e.target.value);
    }

    texInput.current.style.height = '';
    texInput.current.style.height =
      texInput.current.scrollHeight < 100 ? texInput.current.scrollHeight + 'px' : '100px';
  };
  const imageHandler = (e) => {
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState === 2) {
        setPreviewImage(reader.result);
      }
    };
    reader.readAsDataURL(e.target.files[0]);

    const fileArray = Array.from(e.target.files);
    const img = fileArray[0];
    setSendImage(img);
  };
  const selectEmoji = (emoji) => {
    insertTextAtCursor(emoji.native);
    setIsEmoji(false);
  };
  const removeImage = () => {
    setPreviewImage(undefined);
    setSendImage(undefined);
    uploadPhoto.current.value = null;
    uploadImage.current.value = null;
  };
  const handleKeyDown = (event) => {
    if (event?.key === 'Enter') {
      event.preventDefault();
      !isLoading && messageText && handleSend();
    }
  };
  function insertTextAtCursor(text) {
    const editor = texInput.current;
    const selectionStart = editor.selectionStart;
    const selectionEnd = editor.selectionEnd;
    const msg = messageText ?? '';
    const newContent = msg?.slice(0, selectionStart) + text + msg?.slice(selectionEnd);
    setMessageText(newContent);
    editor.selectionStart = editor.selectionEnd = selectionStart + text.length;
    editor.focus();
  }

  return (
    <div className={'message-area'}>
      {previewImage && (
        <div className={'preview-image'}>
          <div className={'remove'} onClick={removeImage}>
            +
          </div>
          <img src={previewImage} alt={'preview'} />
        </div>
      )}
      <textarea
        ref={texInput}
        onChange={(e) => resizeTextArea(e)}
        value={messageText}
        placeholder={'Type your message'}
        onKeyDown={handleKeyDown}
      />
      <div className="buttons-container">
        <div className="attachment-container">
          <input
            ref={uploadImage}
            type="file"
            name="imageUpload"
            accept="image/jpeg, image/png"
            onChange={imageHandler}
            hidden
          />
          <input
            ref={uploadPhoto}
            type="file"
            accept="image/jpeg, image/png"
            capture="user"
            hidden
            onChange={imageHandler}
          />
          <ImAttachment className="attachment-icon" onClick={uploadImageToChat} />
          <GrEmoji
            className="emoji-icon"
            emoji={{ id: 'grinning', skin: 3 }}
            size={20}
            onClick={() => setIsEmoji((prevState) => !prevState)}
          />
          {isEmoji && (
            <Picker
              title="Pick your emoji…"
              emoji="point_up"
              onSelect={(emoji) => selectEmoji(emoji)}
              style={{ position: 'absolute', bottom: '20px', left: '70px' }}
            />
          )}
          {isMobile && <AiOutlineCamera className="attachment-icon" onClick={takePhoto} />}
        </div>
        <button
          className="send-button"
          disabled={
            (!messageText && !previewImage) ||
            isLoading ||
            queue[profileId]?.length ||
            disableSendButton
          }
          onClick={() => handleSend()}
        >
          {isLoading ? <AiOutlineLoading3Quarters className={'rotate'} /> : <IoIosPaperPlane />}
        </button>
      </div>
      {ContinueChatComponent && continueChatting && (
        <ContinueChatComponent
          open={isContinueChattingModalOpen}
          onCancel={() => {
            if (!isLoading) {
              setIsContinueChattingModalOpen(false);
              setIsFromModal(false);
            }
          }}
          openCreditsModal={openCreditsModal}
          handleSend={(selectedPackageId) => {
            setIsFromModal(true);
            handleSend(selectedPackageId);
          }}
          isLoading={isLoading}
          profileId={profileId}
          packageId={packageId}
        />
      )}
    </div>
  );
};
