// noinspection ES6UnusedImports

import './MembersAreaMain.scss';

import { Router, useLocation, useNavigate } from '@reach/router';
import { debounce } from 'lodash';
import moment from 'moment';
// eslint-disable-next-line unused-imports/no-unused-imports,@typescript-eslint/no-unused-vars
import Pusher from 'pusher-js';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSetRecoilState } from 'recoil';

import MessagePopup from '../../../../components/MessagePopup/MessagePopup';
import ModalDialog from '../../../../components/modals/ModalDialog/ModalDialog';
import PaymentNote from '../../../../components/modals/PaymentNote/PaymentNote';
import SuccessMessage from '../../../../components/SuccessMessage/SuccessMessage';
import Unsubscribe from '../../../../components/Unsubscribe/Unsubscribe';
import { useWebSocket } from '../../../../hooks/useWebSocket';
import { userData as userDataNew } from '../../../../recoil/atoms/userDataAtom';
import { CURRENT_USER_CREDITS } from '../../../../redux/types/credits';
import { SET_NOTIFICATIONS } from '../../../../redux/types/notifications';
import { authMe, autoLogIn } from '../../../../services/auth-service/auth.service';
import {
  showEmailVerificationInstruction,
  showMembersAreaPage,
  showRedirectedPage,
  showRootPage
} from '../../../../services/navigation-service/navigation.service';
import {
  getToken,
  hasTokenExpired,
  saveToken
} from '../../../../services/token-service/token.service';
import {
  cleanupUserData,
  getUserData,
  setUserLocalData
} from '../../../../services/user-service/user.service';
import { CreditsPage } from '../../../../V2/pages/creditsPage/CreditsPage';
import { MembersPricing as MembersPricingNew } from '../../../../V2/pages/membersPricing/MembersPricing';
import { ProfilePageMember } from '../../../../V2/pages/profilePageMember/ProfilePageMember';
import { useGetAllPackages } from '../../../../V2/services/packages/useGetAllPackages';
import { useHasSpecialOffer } from '../../../../V2/services/packages/useHasSpecialOffer';
import AboutUs from '../FooterPages/AboutUs/AboutUs';
import AntiHumanTrafficingPolicy from '../FooterPages/AntiHumanTrafficingPolicy/AntiHumanTrafficingPolicy';
import ContactUs from '../FooterPages/ContactUs/ContactUs';
import FAQ from '../FooterPages/FAQ/FAQ';
import NonConsensualParticipationPolicy from '../FooterPages/NonConsensualParticipationPolicy/NonConsensualParticipationPolicy';
import Pricing from '../FooterPages/Pricing/Pricing';
import PrivacyPolicy from '../FooterPages/PrivacyPolicy/PrivacyPolicy';
import TermsAndConditions from '../FooterPages/TermsAndConditions/TermsAndConditions';
import FooterMembersArea from './components/FooterMembersArea/FooterMembersArea';
import HeaderMembersArea from './components/HeaderMembersArea/HeaderMembersArea';
import { DynamicComponent } from './DynamicComponent';
import { MEMBERS_ROUTES } from './members_routes';
import MembersArea from './MembersArea/MembersArea';
import BrowseProfiles from './Pages/BrowseProfiles/BrowseProfiles';
import { InboxConversation } from './Pages/InboxRefactor/components/conversation/InboxConversation';
import { Messages } from './Pages/InboxRefactor/Messages';
import MatchGame from './Pages/MatchGame/MatchGame';
import MembersPricing from './Pages/MembersPricing/MembersPricing';
import MessagesNew from './Pages/MessagesNew/MessagesNew';
import MyFavorites from './Pages/MyFavorites/MyFavorites';
import MyMatches from './Pages/MyMatches/MyMatches';
import Pictures from './Pages/MyProfile/Pictures/Pictures';
import Profile from './Pages/MyProfile/Profile/Profile';
import Settings from './Pages/MyProfile/Settings/Settings';
import PaymentPage from './Pages/PaymentPage/PaymentPage';
import Visitors from './Pages/Visitors/Visitors';

const InterstitialUpdater = () => {
  const location = useLocation();

  useEffect(() => {
    if (window.triggerInterstitial) {
      window.triggerInterstitial();
    }
  }, [location]);

  return null;
};

const MembersAreaMain = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const userData = getUserData();
  const [sideBar, setSidebar] = useState();
  const [onHomePage, setOnHomePage] = useState(false);
  const [unsubscribeUrl, getUnsubscribeUrl] = useState('');
  const [showSuccesMessage, setShowSuccesMessage] = useState(false);
  const queryString = window.location.search;
  const setUserData = useSetRecoilState(userDataNew);
  const navigate = useNavigate();

  const urlParams = new URLSearchParams(queryString);
  const url = useMemo(() => new URL(window.location.href), []);
  const verified = urlParams.get('verified');
  const unsubscribe = urlParams.get('unsubscribe');
  const hash = urlParams.get('hash');
  const paymentStatus = urlParams.get('paymentstatus');
  const [visitors, setVisitors] = useState('');
  const [paymentNote, setPaymentNote] = useState('');
  const paymetnConfirmRef = useRef(null);
  const paymentDeclineRef = useRef(null);
  const unsubscribeRef = useRef(null);
  const action = urlParams.get('action');
  const { data: special } = useHasSpecialOffer();
  const { data: packages } = useGetAllPackages();

  useEffect(() => {
    if (verified || hash || action === 'login') {
      const idData = urlParams.get('id');
      const hashData = urlParams.get('hash');
      const source = urlParams.get('source');

      if (!hashData) {
        return;
      }

      urlParams.delete('id');
      urlParams.delete('hash');
      urlParams.delete('action');

      const messageCheck = window.location.pathname.includes('/messagesnew');
      const redirectUrl = `${url.origin}${url.pathname}?${urlParams.toString()}`;
      cleanupUserData();
      autoLogIn(idData, hashData, source)
        .then((res) => {
          saveToken(res.data.access_token, res.data.expires_in);
          localStorage.setItem('userData', JSON.stringify(res.data.user));
          setUserData(JSON.stringify(res.data.user));

          if (messageCheck) {
            showRedirectedPage(redirectUrl);
            localStorage.setItem('EMAIL_OFFER', redirectUrl);
          } else {
            showMembersAreaPage(window.location.pathname.split('/members-area/')[1]);
          }
        })
        .catch((err) => console.log(err));
    } else if (!getToken() || hasTokenExpired()) {
      localStorage.setItem('pathname', window.location.pathname.split('/members-area/')[1]);
      if (unsubscribe) {
        showRootPage(queryString);
      } else {
        showRootPage();
      }
    }
  }, []);

  useEffect(() => {
    ////...TO ADD CONDITION WHEN USER IS LOGED IN FIRST TIME = false
    location.pathname !== '/members-area' &&
      dispatch({ type: 'SHOW_WELCOME_MESSAGE', payload: false });
  }, [location]);

  useWebSocket();

  const openModal = (ref) => {
    ref.current.openModal();
  };

  const closeModal = (ref) => {
    let url = new URL(location.href);
    // let status = params.get('paymentstatus');
    url.searchParams.delete('paymentstatus');
    ref.current.closeModal();
  };

  const dismissNotifications = () => {
    setPaymentNote('');
    let url = new URL(location.href);
    url.searchParams.delete('paymentstatus');
    window.history.replaceState(null, null, window.location.pathname);
  };
  const closeSuccessPaymentModal = debounce(() => {
    let url = new URL(location.href);
    url.searchParams.delete('paymentstatus');
    setPaymentNote('');
    window.history.replaceState(null, null, window.location.pathname);
  }, 10000);
  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    const unsubscribe = urlParams.get('unsubscribe');
    const unsubscribe_url = urlParams.get('unsubscribe_url');

    getUnsubscribeUrl(unsubscribe_url);

    if (unsubscribe) {
      openModal(unsubscribeRef);
    }
  }, []);

  useEffect(() => {
    var elmnt = document.getElementById('scrollTop');
    elmnt.scrollIntoView();
  }, [window.location.pathname]);

  useEffect(() => {
    const visitors = userData?.unread_visitor_notifications;

    if (visitors > 0) {
      setVisitors(visitors);
    }
  }, [userData?.unread_visitor_notifications]);

  useEffect(() => {
    const url = new URL(window.location);
    const path = url.pathname;
    const giftArray = path?.includes('giftProfile') ? path.split('/') : [];

    // Creating an object from the extracted values
    const extractedData = {
      giftProfile: giftArray.length ? giftArray[giftArray.length - 1] : false,
      paymentstatus: paymentStatus
    };
    const status = extractedData.paymentstatus;
    const profile = extractedData.giftProfile;
    if (status === '0' && profile) navigate(`/members-area/messagesnew/${profile}`);
    if (status === '1' && profile) navigate(`/members-area/member-profile/${profile}`);
    if (urlParams.has('paymentstatus')) {
      Number(paymentStatus) === 1 ? setPaymentNote('declined') : setPaymentNote('approved');
      setTimeout(() => {
        authMe()
          .then((res) => {
            dispatch({
              type: CURRENT_USER_CREDITS,
              payload: res.data.data.credits
            });
            const paymentInfo = res.data.data.unread_notifications?.filter(
              (note) => note.type === 'transaction'
            );
            if (paymentInfo?.length > 0) {
              const note = paymentInfo[paymentInfo.length - 1];
              setPaymentNote(note?.notifiable.status);
            }
            let url = new URL(location.href);
            //url.searchParams.delete('paymentstatus');
            window.history.replaceState(null, null, window.location.pathname);
            setUserLocalData(res.data);
          })
          .catch((err) => console.log(err));
      }, 2000);
    }
  }, [location.href, navigate, paymentStatus, queryString, urlParams]);

  const dataFromLocalStorage = JSON.parse(localStorage.getItem('userData'));

  useEffect(() => {
    dispatch({
      type: CURRENT_USER_CREDITS,
      payload: dataFromLocalStorage?.credits
    });
    dispatch({
      type: SET_NOTIFICATIONS,
      payload: dataFromLocalStorage?.unread_message_notifications || []
    });
  }, [dataFromLocalStorage?.credits, dataFromLocalStorage?.unread_message_notifications]);

  useEffect(() => {
    const now = moment(new Date());
    const created = moment(userData?.created_at);
    const duration = moment.duration(now.diff(created));
    const hours = duration.asHours();
    if (
      userData?.click_id &&
      !userData?.email_verified_at &&
      !userData?.auto_verified_at &&
      Math.round(hours) < 24 &&
      !verified
    ) {
      showEmailVerificationInstruction();
    }

    const urlLocation = window.location.pathname;

    if (urlLocation === '/members-area') {
      setOnHomePage(true);
    }
    if (!userData) {
      showRootPage();
    }

    if (paymentNote === 'approved') {
      openModal(paymetnConfirmRef);

      setTimeout(() => {
        closeModal(paymetnConfirmRef);
      }, 5000);
    }

    if (paymentNote === 'declined') {
      openModal(paymentDeclineRef);
    }
  }, [paymentNote]);

  const paymentConfirmModal = () => {
    return (
      <ModalDialog
        className="PaymetnConfirmWrapper"
        ref={paymetnConfirmRef}
        hideLogo={true}
        disbleClose
        handleClose={closeSuccessPaymentModal}
      >
        <PaymentNote
          closeModal={closeModal}
          modalRef={paymetnConfirmRef}
          text="Your payment was successful"
          note="You can close this window now"
          status="confirm"
        />
      </ModalDialog>
    );
  };

  const paymentDeclineModal = () => {
    return (
      <ModalDialog
        className="PaymetnConfirmWrapper"
        ref={paymentDeclineRef}
        hideLogo={true}
        handleClose={dismissNotifications}
      >
        <PaymentNote
          closeModal={closeModal}
          modalRef={paymentDeclineRef}
          text="Unfortunately, your payment was declined"
          note="Please try again or use an alternative credit card"
          status="decline"
        />
      </ModalDialog>
    );
  };

  const unsubscribeModal = () => {
    return (
      <ModalDialog ref={unsubscribeRef}>
        <Unsubscribe
          closeModal={closeModal}
          unsubscribeUrl={unsubscribeUrl}
          isUnsubscribeSuccess={setShowSuccesMessage}
          modalRef={unsubscribeRef}
          openModal={openModal}
        />
      </ModalDialog>
    );
  };

  return (
    <div id="scrollTop" className={onHomePage ? 'MembersAreaMain-HomeOpen' : 'MembersAreaMain'}>
      <HeaderMembersArea sideBarValue={setSidebar} visitors={visitors} setVisitors={setVisitors} />
      <div className={sideBar ? 'MembersAreaContent SidebarOpen' : 'MembersAreaContent'}>
        <InterstitialUpdater />
        <Router primary={false}>
          <MembersArea
            default
            sidebarValue={sideBar}
            visitors={visitors}
            setVisitors={setVisitors}
          />
          {/*<BrowseProfilesRefactor default sidebarValue={sideBar} />*/}
          <Messages sideBarValue={sideBar} path={`${MEMBERS_ROUTES.MESSAGESNEW}/`}>
            <InboxConversation path={':id'} />
            <InboxConversation path={':id/:conversationId'} />
          </Messages>
          <MessagesNew sideBarValue={sideBar} path={`${MEMBERS_ROUTES.MESSAGES}/*`} />
          <BrowseProfiles sidebarValue={sideBar} path={`${MEMBERS_ROUTES.BROWSEPROFILES}`} />
          <MatchGame sidebarValue={sideBar} path={`${MEMBERS_ROUTES.MATCHGAME}`} />
          <MyMatches path={`${MEMBERS_ROUTES.MYMATCHES}`} />
          <MyFavorites path={`${MEMBERS_ROUTES.MYFAVORITES}`} />
          <Visitors
            path={`${MEMBERS_ROUTES.VISITORS}`}
            setVisitors={setVisitors}
            visitors={visitors}
          />
          <DynamicComponent
            path={`${MEMBERS_ROUTES.MYPROFILE}`}
            isSplitTest={userData?.my_profile_version === 1}
          />
          <Profile path={`${MEMBERS_ROUTES.PROFILE}`} />
          <Pictures path={`${MEMBERS_ROUTES.PROFILEPICTURES}`} />
          <Settings path={`${MEMBERS_ROUTES.PROFILESETTINGS}`} />
          <PaymentPage path={`${MEMBERS_ROUTES.PAYMENT}`} />
          <MyMatches path={`${MEMBERS_ROUTES.MYMATCHES}`} />
          <CreditsPage path={`${MEMBERS_ROUTES.CREDITS}`} />
          <ProfilePageMember //MembersProfilePage
            sidebarValue={sideBar}
            path={`${MEMBERS_ROUTES.MEMBERPROFILE}/:userId/*/*`}
          />
          <MembersPricingNew path={`${MEMBERS_ROUTES.MEMBERSPRICING}`} />
          <MembersPricing path={`${MEMBERS_ROUTES.MEMBERSPRICING}1`} />
          <AboutUs path={`${MEMBERS_ROUTES.ABOUT}`} />
          <FAQ path={`${MEMBERS_ROUTES.FAQ}`} />
          <Pricing path={`${MEMBERS_ROUTES.PRICING}`} />
          <ContactUs path={`${MEMBERS_ROUTES.CONTACTUS}`} />
          <TermsAndConditions path={`${MEMBERS_ROUTES.TERMS}`} />
          <AntiHumanTrafficingPolicy path={`${MEMBERS_ROUTES.ANTIHUMANTRAFICKING}`} />
          <NonConsensualParticipationPolicy path={`${MEMBERS_ROUTES.NONCONSENSUALPARTICIPATION}`} />
          <TermsAndConditions path={`${MEMBERS_ROUTES.TERMS}`} />
          <PrivacyPolicy path={`${MEMBERS_ROUTES.PRIVACY}`} />
        </Router>
      </div>
      <FooterMembersArea sidebarValue={sideBar} />
      <MessagePopup />
      {paymentConfirmModal()}
      {paymentDeclineModal()}
      {unsubscribeModal()}
      <SuccessMessage
        showMessage={showSuccesMessage}
        setShowMessage={setShowSuccesMessage}
        message="Unsubscribe successful"
      />
    </div>
  );
};

export default MembersAreaMain;
