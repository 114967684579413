import './creditsCardForm.scss';

import { ArrowLeftOutlined, LoadingOutlined } from '@ant-design/icons';
import { notification, Spin } from 'antd';
import React, { useCallback, useState } from 'react';

import cvvSrc from '../../../../assets/images/cvv.svg';
import paymentCardSrc from '../../../../assets/images/payment_cards.svg';
import {
  formatCardNumber,
  formatCVV,
  formatExpiry,
  formatZipCode
} from '../../../helpers/creditCardFormatters';
import { CreditCardErrors, validateCreditCardForm } from '../../../helpers/creditCardValidation';
import { currencySymbol } from '../../../helpers/currency';
import { formatNumberWithDecimalPlaces } from '../../../helpers/numberHelpers';
import { PurchaseType } from '../../../model/enum/PurchaseType';
import { useFlipCardPayment } from '../../../services/payment/useFlipCardPayment';

export const CreditsCardForm = ({
  setShowPaymentForm,
  isExiting,
  totalPrice,
  currency,
  packageId,
  redirectUrl,
  trigger,
  profileId,
  buttonColor,
  onSuccess,
  number_of_messages,
  lastMessageId
}: {
  setShowPaymentForm: (show: boolean) => void;
  isExiting?: boolean;
  totalPrice: number;
  currency?: string;
  packageId: number;
  redirectUrl?: string;
  trigger?: 'credits_page' | 'credits_modal';
  profileId?: number;
  buttonColor?: string;
  onSuccess?: () => void;
  number_of_messages?: number;
  lastMessageId?: number;
}) => {
  const { mutate: flipCardPayment } = useFlipCardPayment();
  const [state, setState] = useState({
    number: '',
    expiry: '',
    cvv: '',
    name: '',
    focus: '',
    zipCode: '',
    expiryMonth: '',
    expiryYear: ''
  });
  const [errors, setErrors] = useState<CreditCardErrors>({});
  const [loading, setLoading] = useState(false);

  const handleInputChange = (evt: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = evt.target;

    // Clear the error for the field being edited
    setErrors((prev) => ({ ...prev, [name]: undefined }));

    // Format the input based on the field
    let formattedValue = value;
    if (name === 'number') {
      formattedValue = formatCardNumber(value);
    } else if (name === 'expiryMonth' || name === 'expiryYear') {
      const month = name === 'expiryMonth' ? value : state.expiryMonth;
      const year = name === 'expiryYear' ? value : state.expiryYear;
      const expiry = formatExpiry(month, year);
      setState((prev) => ({ ...prev, expiry }));
      // Clear expiry error when either month or year changes
      setErrors((prev) => ({ ...prev, expiry: undefined }));
    } else if (name === 'cvv') {
      formattedValue = formatCVV(value);
    } else if (name === 'zipCode') {
      formattedValue = formatZipCode(value);
    }

    setState((prev) => ({ ...prev, [name]: formattedValue }));
  };

  const handleInputFocus = (evt: React.FocusEvent<HTMLInputElement | HTMLSelectElement>) => {
    setState((prev) => ({ ...prev, focus: evt.target.name }));
  };

  const handleBackClick = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      setShowPaymentForm(false);
    },
    [setShowPaymentForm]
  );

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    const { isValid, errors: validationErrors } = validateCreditCardForm(state);
    setErrors(validationErrors);

    if (!isValid) {
      return;
    }

    setLoading(true);
    const cardNumber = state.number.replace(/\s/g, '');

    flipCardPayment(
      {
        package_id: packageId,
        type: PurchaseType.PURCHASE,
        redirect_url: redirectUrl,
        subscription_trigger: trigger,
        profile_id: profileId,
        card_number: cardNumber,
        expiry_date: `${state.expiryMonth}/${state.expiryYear}`,
        cvv: state.cvv,
        cardholder_name: state.name,
        zip_code: state.zipCode,
        last_message_id: lastMessageId
      },
      {
        onSuccess: (response) => {
          const { charged, redirect_url } = response[0];
          if (charged) {
            setState({
              number: '',
              expiry: '',
              cvv: '',
              name: '',
              focus: '',
              zipCode: '',
              expiryMonth: '',
              expiryYear: ''
            });
            setErrors({});
            notification.success({ message: 'Payment successful' });
            onSuccess?.();
            return;
          }
          if (redirect_url) {
            window.open(redirect_url, '_self');
          }
          setShowPaymentForm(false);
        },
        onSettled: () => setLoading(false)
      }
    );
  };

  return (
    <div
      className={`credits-card-form-wrapper ${isExiting ? 'exit' : ''}`}
      onClick={(e) => {
        // Don't prevent events if clicking the submit button
        if (!(e.target instanceof Element) || !e.target.closest('button[type="submit"]')) {
          e.preventDefault();
          e.stopPropagation();
        }
      }}
    >
      {loading && (
        <div className="loading-overlay">
          <Spin indicator={<LoadingOutlined style={{ fontSize: 36, color: buttonColor }} spin />} />
          <p>
            Please wait while we process your payment.
            <br />
            Do not close this page.
          </p>
        </div>
      )}
      {!loading && (
        <button className="back-button" onClick={handleBackClick}>
          <ArrowLeftOutlined />
        </button>
      )}
      <div className="card-form-content">
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="name">Cardholder Name</label>
            <input
              type="text"
              name="name"
              placeholder="John Smith"
              value={state.name}
              onChange={handleInputChange}
              onFocus={handleInputFocus}
              className={errors.name ? 'invalid' : ''}
              required
            />
            {errors.name && <div className="error-message">{errors.name}</div>}
          </div>
          <div className="form-group">
            <label htmlFor="number">Card Number</label>
            <input
              type="tel"
              name="number"
              placeholder="1234 5678 1234 5678"
              value={state.number}
              onChange={handleInputChange}
              onFocus={handleInputFocus}
              className={errors.number ? 'invalid' : ''}
              required
            />
            <img src={paymentCardSrc} alt="Payment Card" className="form-input-icon payment-card" />
            {errors.number && <div className="error-message">{errors.number}</div>}
          </div>
          <div className="form-group expiry-group">
            <label htmlFor="expiryMonth">Expiration Date</label>
            <div className="expiry-selects">
              <select
                name="expiryMonth"
                value={state.expiryMonth}
                onChange={handleInputChange}
                onFocus={handleInputFocus}
                required
                className={errors.expiry ? 'invalid' : ''}
              >
                <option value="" disabled>
                  Month
                </option>
                {Array.from({ length: 12 }, (_, i) => {
                  const month = (i + 1).toString().padStart(2, '0');
                  return (
                    <option key={month} value={month}>
                      {month}
                    </option>
                  );
                })}
              </select>
              <select
                name="expiryYear"
                value={state.expiryYear}
                onChange={handleInputChange}
                onFocus={handleInputFocus}
                required
                className={errors.expiry ? 'invalid' : ''}
              >
                <option value="" disabled>
                  Year
                </option>
                {Array.from({ length: 12 }, (_, i) => {
                  const fullYear = (new Date().getFullYear() + i).toString();
                  const shortYear = fullYear.slice(-2);
                  return (
                    <option key={fullYear} value={fullYear}>
                      {shortYear}
                    </option>
                  );
                })}
              </select>
            </div>
            {errors.expiry && <div className="error-message">{errors.expiry}</div>}
          </div>
          <div className="form-row">
            <div className="form-group">
              <label htmlFor="cvv">Security Code</label>
              <input
                type="tel"
                name="cvv"
                placeholder="CVC"
                value={state.cvv}
                onChange={handleInputChange}
                onFocus={handleInputFocus}
                className={errors.cvv ? 'invalid' : ''}
                required
              />
              <img src={cvvSrc} alt="CVV" className="form-input-icon cvv-image" />
              {errors.cvv && <div className="error-message">{errors.cvv}</div>}
            </div>
            <div className="form-group">
              <label htmlFor="zipCode">Zip Code</label>
              <input
                type="tel"
                name="zipCode"
                placeholder="Zip Code"
                value={state.zipCode}
                onChange={handleInputChange}
                onFocus={handleInputFocus}
                className={errors.zipCode ? 'invalid' : ''}
                required
              />
              {errors.zipCode && <div className="error-message">{errors.zipCode}</div>}
            </div>
          </div>
          <button
            type="submit"
            className={`submit-button ${loading ? 'disabled' : ''}`}
            disabled={loading}
            style={{ backgroundColor: buttonColor }}
          >
            {loading ? 'Processing...' : 'Pay Now'}
            <span className="price">
              {number_of_messages ? `${number_of_messages} credits` : ''}
              {' - '}
              {currency && currencySymbol(currency as Currency)}{' '}
              {formatNumberWithDecimalPlaces(totalPrice, 2)}
            </span>
          </button>
        </form>
      </div>
    </div>
  );
};
