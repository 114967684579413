export const validateLuhn = (cardNumber: string): boolean => {
  const digits = cardNumber.replace(/\D/g, '');

  // Check if the card number has exactly 16 digits
  if (digits.length !== 16) return false;

  let sum = 0;
  let alternate = false;
  const digitArray = digits.split('').reverse();

  for (let i = 0; i < digitArray.length; i++) {
    let num = parseInt(digitArray[i], 10);
    if (alternate) {
      num *= 2;
      if (num > 9) num -= 9;
    }
    sum += num;
    alternate = !alternate;
  }

  return sum % 10 === 0;
};

export const validateCardholderName = (name: string): boolean => {
  const trimmedName = name.trim();
  if (trimmedName.length < 3) return false;
  return /^[A-Za-z]+([A-Za-z .'-]*[A-Za-z])?$/.test(trimmedName);
};

export const validateExpiry = (month: string, year: string): boolean => {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth() + 1; // getMonth() returns 0-11

  // Convert to numbers for comparison
  const enteredMonth = parseInt(month, 10);
  const enteredYear = parseInt(year, 10);

  // Validate month format
  if (enteredMonth < 1 || enteredMonth > 12) {
    return false;
  }

  // Validate year range (current year to next 12 years)
  if (enteredYear < currentYear || enteredYear > currentYear + 12) {
    return false;
  }

  // If it's current year, make sure month is not in the past
  if (enteredYear === currentYear && enteredMonth < currentMonth) {
    return false;
  }

  return true;
};

export const validateCVV = (cvv: string): boolean => {
  const digits = cvv.replace(/\D/g, '');
  return digits.length === 3;
};

export const validateZipCode = (zipCode: string): boolean => {
  const digits = zipCode.replace(/\D/g, '');
  return digits.length === 5;
};

export interface CreditCardErrors {
  name?: string;
  number?: string;
  expiry?: string;
  cvv?: string;
  zipCode?: string;
}

export const validateCreditCardForm = (state: {
  name: string;
  number: string;
  expiryMonth: string;
  expiryYear: string;
  cvv: string;
  zipCode: string;
}): { isValid: boolean; errors: CreditCardErrors } => {
  const errors: CreditCardErrors = {};

  // Validate name
  if (!validateCardholderName(state.name)) {
    errors.name = 'Please enter a valid name';
  }

  // Validate card number
  const cardNumber = state.number.replace(/\s/g, '');
  if (!validateLuhn(cardNumber)) {
    errors.number = 'Please enter a valid card number';
  }

  // Validate expiry
  if (!validateExpiry(state.expiryMonth, state.expiryYear)) {
    errors.expiry = 'Invalid expiry date';
  }

  // Validate CVV
  if (!validateCVV(state.cvv)) {
    errors.cvv = '3 digits required';
  }

  // Validate ZIP code
  if (!validateZipCode(state.zipCode)) {
    errors.zipCode = '5 digits required';
  }

  return {
    isValid: Object.keys(errors).length === 0,
    errors
  };
};
