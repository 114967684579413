import { message } from 'antd';
import moment from 'moment/moment';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useRecoilState } from 'recoil';

import { queryClient } from '../../../../../../../../App';
import { useGetArchivedConversation } from '../../../../../../../../core/messages/useGetArchivedConversation';
import { key as ConvKey } from '../../../../../../../../core/messages/useGetConversationById';
import { queueMessage } from '../../../../../../../../recoil/atoms/queueMessageAtom';
import { ArchivedConversation } from '../ArchivedConversation';
import { MessageBubble } from './MessageBuble';

export const MessageExchangeList = ({
  messages,
  profile,
  convId,
  failedMessages,
  openBuy,
  hasPages,
  loadPages,
  loading,
  canRestoreMessages,
  profileUsername
}) => {
  const [queue] = useRecoilState(queueMessage);
  const scrollRef = useRef(null);
  const bottomRef = useRef(null);
  const topRef = useRef(null);
  const [scrolledTo, setScrolledTo] = useState(0);
  const [messageApi, contextHolder] = message.useMessage();
  const { mutate: getArchivedConversation, isLoading: isLoadingArchivedConversation } =
    useGetArchivedConversation();

  const handleGetArchivedConversation = () => {
    getArchivedConversation({
      profileId: Number(profile)
    });
  };
  const loadingMessages = useCallback(() => {
    messageApi.open({
      type: 'loading',
      top: 0,
      content: 'updating conversation...',
      duration: 0,
      className: 'my-message'
    });
  }, [messageApi]);
  useEffect(() => {
    loading && loadingMessages();
    !loading && messageApi.destroy();
  }, [loading]);
  const renderDate = (time) => {
    const date = new Date(time);
    const today = new Date();
    const dateFormatted = moment(date).format('yyy-MM-DD');
    const todayFormatted = moment(today).format('yyy-MM-DD');
    return (
      <div className="DayShowContainer" key={time}>
        <span> {dateFormatted === todayFormatted ? 'Today' : dateFormatted} </span>
      </div>
    );
  };
  const handleScroll = useCallback(() => {
    const element = scrollRef.current;
    if (element) {
      const { scrollTop, scrollHeight } = element;
      if (scrollTop === 0) {
        setScrolledTo(scrollHeight);
        hasPages && loadPages();
      }
    }
  }, [hasPages, loadPages]);
  useEffect(() => {
    if (scrollRef.current) {
      const { scrollHeight } = scrollRef.current;
      scrollRef.current.scrollTop = scrollHeight - scrolledTo;
    }
  }, [messages, scrolledTo]);
  useEffect(() => {
    setScrolledTo(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queue[convId], profile]);
  useEffect(() => {
    const element = scrollRef.current;
    if (element) {
      element.addEventListener('scroll', handleScroll);
    }
    return () => {
      if (element) {
        element.removeEventListener('scroll', handleScroll);
      }
    };
  }, [scrollRef, handleScroll]);
  const showDate = (index, item, reversed) => {
    const currentCreatedAt = new Date(item?.created_at);
    const currentCreatedAtDate = moment(currentCreatedAt).format('yyy-MM-DD');

    const nextCreatedAt = new Date(reversed[index - 1]?.created_at);
    const nextCreatedAtDate = moment(nextCreatedAt).format('yyy-MM-DD');

    return currentCreatedAtDate !== nextCreatedAtDate || !index;
  };
  useEffect(() => {
    setScrolledTo(0);
  }, [queue]);
  useEffect(() => {
    queryClient.invalidateQueries([ConvKey, profile]);
  }, [profile]);
  const renderMessages = () => {
    if (messages) {
      const data = [...messages, ...failedMessages].sort(
        (a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime()
      );
      return data.map((message, index) => {
        if (!message) return;
        return (
          <React.Fragment key={message.id}>
            {showDate(index, message, data) && renderDate(message.created_at)}
            <>
              <MessageBubble
                messageId={message.id}
                openBuy={openBuy}
                failed={message.failed}
                body={message.body}
                time={message.created_at}
                is_gifting={message.is_gifting}
                attachment={message.attachments && message.attachments[0]}
                sender={Number(profile) === Number(message.sender_profile_id)}
                ref={index === scrolledTo ? bottomRef : topRef}
              />
            </>
          </React.Fragment>
        );
      });
    }
  };

  const showArchivedConversationComponent = () => {
    if (!canRestoreMessages) return null;
    return (
      <ArchivedConversation
        profileUsername={profileUsername}
        onClick={handleGetArchivedConversation}
        isLoading={isLoadingArchivedConversation}
      />
    );
  };

  return (
    <>
      <div className={'conv-list'} ref={scrollRef}>
        {contextHolder}
        {renderMessages()}
        {showArchivedConversationComponent()}
        {queue[profile]?.map((queueMessage, index) => (
          <MessageBubble
            queue
            key={index}
            messageId={index}
            loading
            failed={false}
            body={queueMessage?.body}
            time={queueMessage?.created_at}
            attachment={queueMessage?.attachments && queueMessage?.attachments[0]}
            sender={false}
            openBuy={openBuy}
          />
        ))}
      </div>
    </>
  );
};
