import { globalHistory } from '@reach/router';
import { useEffect } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';

import { showPopUnder } from '../../recoil/atoms/showPopUnder';
import { userData as userRecoil } from '../../recoil/atoms/userDataAtom';
import { useShowPopUnder } from '../../V2/services/popunder/useShowPopUnder';
import { AD_ZONE_CONFIGS, appendAdZones, removeAdZones, setShowPopupState } from './adZoneV2';
import {
  executePopUnder,
  getPopUnderExpiration,
  removePopUnderOverlay,
  updatePopUnderHref
} from './helpers';

const AD_ZONE_ALLOWED_PATHS = Object.keys(AD_ZONE_CONFIGS);

const usePopUnder = (isPopUnderEnabled) => {
  const setShowPopUnder = useSetRecoilState(showPopUnder);
  const [userData] = useRecoilState(userRecoil);
  const { refetch } = useShowPopUnder();

  useEffect(() => {
    if (userData?.data) {
      updatePopUnderHref();
    }
  });

  useEffect(() => {
    const shouldShowPopUnder = async () => {
      const { data } = await refetch();
      setShowPopupState(data?.showPopUnder);
      setShowPopUnder(data?.showPopUnder);

      return data?.showPopUnder;
    };

    const shouldDisplayPopUnder = () => {
      if (!isPopUnderEnabled) {
        return false;
      }

      const popUnderExpirationValue = getPopUnderExpiration();
      const popUnderExpirationDate = popUnderExpirationValue
        ? new Date(parseInt(popUnderExpirationValue))
        : null;

      return !popUnderExpirationDate || new Date() >= popUnderExpirationDate;
    };

    const handleShowPopUnder = async () => {
      const showPopUnder = await shouldShowPopUnder();

      if (!AD_ZONE_ALLOWED_PATHS.includes(window.location.pathname) && !shouldDisplayPopUnder()) {
        removePopUnderOverlay();
        removeAdZones();
        return;
      }

      if (showPopUnder) {
        shouldDisplayPopUnder() ? executePopUnder() : removePopUnderOverlay();

        if (AD_ZONE_ALLOWED_PATHS.includes(window.location.pathname)) {
          appendAdZones();
        }
      } else {
        removePopUnderOverlay();
        removeAdZones();
      }
    };

    const initPopUnder = async () => {
      try {
        handleShowPopUnder();
      } catch (error) {
        console.error('Error initializing pop under:', error);
      }
    };

    if (userData?.data) {
      initPopUnder();
      updatePopUnderHref();
    }

    const triggerPopUnder = globalHistory.listen(({ location }) => {
      removeAdZones();

      if (userData?.data) {
        initPopUnder();
        updatePopUnderHref();
      }
    });

    return () => {
      triggerPopUnder();
    };
  }, [userData?.data, refetch, isPopUnderEnabled]);

  return null;
};

export default usePopUnder;
