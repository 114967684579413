import {
  AppstoreOutlined,
  DollarOutlined,
  EyeOutlined,
  HeartOutlined,
  UserOutlined
} from '@ant-design/icons';
import { IoChatbubbleOutline, IoCopyOutline } from 'react-icons/io5';
import { MdLocalFireDepartment } from 'react-icons/md';
import { VscSignOut } from 'react-icons/vsc';

import { CamsIcon, DatingIcon, GamesIcon } from '../../../../../../assets/images';
import { MEMBERS_ROUTES } from '../../members_routes';
import {
  browseProfilesPage,
  credits,
  matchGame,
  myFavorites,
  myMatches,
  myProfile,
  showMessagesPage,
  visitors
} from '../../navigation.service.js';

const iconStyle = { fontSize: '1.5rem' };

const isMobile = window.innerWidth <= 1024;
const camsSiteID = isMobile ? 'lf_us_cams_mob' : 'lf_us_cams_desktop';
const datingSiteID = isMobile ? 'lf_us_dating_mob' : 'lf_us_dating_desktop';
const gamesSiteID = isMobile ? 'lf_us_games_mob' : 'lf_us_games_desktop';

const SidebarData = ({ isAdZoneCamsEnabled, isAdZoneDatingEnabled, isAdZoneGamesEnabled }) => [
  {
    text: 'Messages',
    notification: 42,
    route: showMessagesPage,
    icon: <IoChatbubbleOutline style={iconStyle} />,
    bounce: true,
    navigate: `${MEMBERS_ROUTES.MEMBERSAREA}${MEMBERS_ROUTES.MESSAGESNEW}`
  },
  {
    text: 'Browse Profiles',
    notification: '',
    route: browseProfilesPage,
    icon: <AppstoreOutlined style={iconStyle} />,
    navigate: `${MEMBERS_ROUTES.MEMBERSAREA}`
  },
  {
    text: 'Match Game',
    notification: '',
    route: matchGame,
    class: 'Match-Game',
    icon: <IoCopyOutline style={iconStyle} />,
    navigate: `${MEMBERS_ROUTES.MEMBERSAREA}${MEMBERS_ROUTES.MATCHGAME}`
  },
  {
    text: 'My Matches',
    notification: '',
    route: myMatches,
    icon: <MdLocalFireDepartment style={iconStyle} />,
    class: 'My-Matches',
    navigate: `${MEMBERS_ROUTES.MEMBERSAREA}${MEMBERS_ROUTES.MYMATCHES}`
  },
  {
    text: 'My Favorites',
    notification: '',
    route: myFavorites,
    icon: <HeartOutlined style={iconStyle} />,
    class: 'My-Favorites',
    navigate: `${MEMBERS_ROUTES.MEMBERSAREA}${MEMBERS_ROUTES.MYFAVORITES}`
  },
  ...(isAdZoneCamsEnabled
    ? [
        {
          text: 'Cams',
          notification: '',
          icon: <img src={CamsIcon} alt="cams" width={24} height={24} />,
          class: 'Cams',
          navigate: `https://a.7amz.com/loader?a=1033&s=135&t=77&p=242&s1=${camsSiteID}`
        }
      ]
    : []),
  ...(isAdZoneDatingEnabled
    ? [
        {
          text: 'Dating',
          notification: 'New',
          icon: <img src={DatingIcon} alt="dating" width={24} height={24} />,
          class: 'Dating',
          navigate: `https://a.7amz.com/loader?a=1032&s=135&t=86&p=242&s1=${datingSiteID}`
        }
      ]
    : []),
  ...(isAdZoneGamesEnabled
    ? [
        {
          text: 'Games',
          notification: 'New',
          icon: <img src={GamesIcon} alt="games" width={24} height={24} />,
          class: 'Games',
          navigate: `https://a.7amz.com/loader?a=1034&s=135&t=92&p=242&s1=${gamesSiteID}`
        }
      ]
    : []),
  {
    text: 'Visitors',
    notification: 32,
    route: visitors,
    icon: <EyeOutlined style={iconStyle} />,
    class: 'Visitors',
    bounce: true,
    navigate: `${MEMBERS_ROUTES.MEMBERSAREA}${MEMBERS_ROUTES.VISITORS}`
  },
  {
    text: 'Credits',
    route: credits,
    icon: <DollarOutlined style={iconStyle} />,
    class: 'Credits',
    navigate: `${MEMBERS_ROUTES.MEMBERSAREA}${MEMBERS_ROUTES.CREDITS}`
  },
  {
    text: 'My Profile',
    notification: '',
    route: myProfile,
    icon: <UserOutlined style={iconStyle} />,
    class: 'My-Profile',
    navigate: `${MEMBERS_ROUTES.MEMBERSAREA}${MEMBERS_ROUTES.MYPROFILE}`
  },
  {
    text: 'Sign Out',
    icon: <VscSignOut style={iconStyle} />,
    notification: '',
    class: 'SignOut',
    name: 'signout'
  }
];

export default SidebarData;
