/* eslint-disable react/no-unescaped-entities */
import './AntiHumanTrafficingPolicy.scss';

import React from 'react';

import HEADERIMAGE from '../../../../../assets/images/HeadersImages/TermsAndCond-Header.png';
import HeaderLayout from '../../../../../components/HeaderLayout/HeaderLayout';

const AntiHumanTrafficingPolicy = () => {
  const title = 'Anti-Human Trafficking Policy';
  const description = 'Effective Date: December 12, 2024';
  const image = HEADERIMAGE;
  return (
    <div className="Anti-Human-Trafficing">
      <HeaderLayout title={title} description={description} image={image} />

      <div className="Anti-Human-Trafficing-Container">
        <p>Effective Date: December 12, 2024</p>
        <h3>1. Purpose</h3>
        <p>
          LocalFlirt.com (www.localflirt.com) is committed to providing a safe and ethical online
          environment for all users. This policy demonstrates our zero-tolerance stance on human
          trafficking, including sex trafficking and forced labor. We aim to prevent exploitation
          and abuse on our platform, fully complying with all applicable laws and best practices. By
          using LocalFlirt.com, you agree to this policy.
        </p>
        <h3>2. Definitions:</h3>
        <p>This policy uses the following definitions:</p>
        <p>
          <strong>Human Trafficking:</strong> The act of recruiting, harboring, transporting,
          providing, or obtaining a person for labor or services through the use of force, fraud, or
          coercion. This includes sex trafficking, forced labor, and other forms of exploitation.
        </p>
        <p>
          <strong>Sex Trafficking:</strong> The recruitment, harboring, transportation, provision,
          or obtaining of a person for the purpose of a commercial sex act, induced by force, fraud,
          or coercion.
        </p>
        <p>
          <strong>Forced Labor:</strong> The exploitation of a person through force, fraud, or
          coercion to perform labor or services against their will.
        </p>
        <p>
          <strong>Coercion:</strong> Includes threats of serious harm, abuse of legal process, and
          other forms of duress that compel an individual to perform labor or services against their
          will.
        </p>
        <p>
          <strong>Fraud:</strong> Includes deception or misrepresentation used to obtain an
          individual's labor or services.
        </p>
        <h3>3. Our Commitment:</h3>
        <p>
          LocalFlirt.com is dedicated to preventing and addressing human trafficking. We actively
          work to:
        </p>
        <p>
          <strong>Create a Safe Environment:</strong> Foster a platform that is free from
          exploitation and abuse.<br></br>
          <strong>Proactive Monitoring:</strong> Utilize advanced technology and manual reviews to
          identify potential indicators of human trafficking in user-generated content and user
          behavior. This includes analyzing profiles, messages, photos, and videos.<br></br>
          <strong>Employee Training:</strong> Our team receives regular training on recognizing and
          responding to potential human trafficking.
        </p>
        <h3>4. Reporting Mechanisms:</h3>
        <p>
          We commit to providing resources to potential victims who are experiencing negative
          situations, reminding them that it is essential to seek help and support. We encourage
          users to report all incidents on LocalFlirt.com
        </p>
        <ul>
          <li>
            Reach out to our support team via email at support@localflirt.com or by calling +1
            877-381-0324.
          </li>
        </ul>
        <ul>
          <p>and consider reaching out to one of the organizations below for assistance:</p>
          <li>
            RAINN&apos;s National Sexual Assault Hotline: 1-800-656-HOPE (4673) |{' '}
            <a
              rel="noreferrer"
              target="_blank"
              style={{ color: '#9f0220' }}
              href="https://online.rainn.org"
            >
              online.rainn.org
            </a>{' '}
            |{' '}
            <a
              rel="noreferrer"
              target="_blank"
              style={{ color: '#9f0220' }}
              href="https://www.rainn.org"
            >
              www.rainn.org
            </a>
          </li>
          <li>
            National Domestic Violence Hotline: 1-800-799-SAFE (7233) or 1-800-787-3224 |{' '}
            <a
              rel="noreferrer"
              target="_blank"
              style={{ color: '#9f0220' }}
              href="https://www.thehotline.org"
            >
              www.thehotline.org
            </a>
          </li>
          <li>
            National Human Trafficking Hotline: Call 1-888-373-7888 or text 233733 |{' '}
            <a
              rel="noreferrer"
              target="_blank"
              style={{ color: '#9f0220' }}
              href="https://www.humantraffickinghotline.org"
            >
              www.humantraffickinghotline.org
            </a>
          </li>
          <li>
            National Sexual Violence Resource Center: 1-877-739-3895 |{' '}
            <a
              rel="noreferrer"
              target="_blank"
              style={{ color: '#9f0220' }}
              href="https://www.nsvrc.org"
            >
              www.nsvrc.org
            </a>
          </li>
          <li>
            National Center for Missing & Exploited Children: 1-800-THE-LOST (843-5678) |{' '}
            <a
              rel="noreferrer"
              target="_blank"
              style={{ color: '#9f0220' }}
              href="https://www.cybertipline.com"
            >
              www.cybertipline.com
            </a>
          </li>
          <li>
            Cyber Civil Rights Initiative: 1-844-878-2274 |{' '}
            <a
              rel="noreferrer"
              target="_blank"
              style={{ color: '#9f0220' }}
              href="https://www.cybercivilrights.org"
            >
              www.cybercivilrights.org
            </a>
          </li>
          <li>
            VictimConnect - Crime Victim Resource Center: 1-855-4VICTIM (855-484-2846) |{' '}
            <a
              rel="noreferrer"
              target="_blank"
              style={{ color: '#9f0220' }}
              href="https://www.victimconnect.org"
            >
              www.victimconnect.org
            </a>
          </li>
          <li>
            FBI Online Crime Complaint Center:{' '}
            <a
              rel="noreferrer"
              target="_blank"
              style={{ color: '#9f0220' }}
              href="https://www.ic3.gov"
            >
              www.ic3.gov
            </a>
          </li>
        </ul>
        <h3>5. Response to Reports:</h3>
        <p>
          Upon receiving a report or identifying potential trafficking indicators, LocalFlirt.com
          will:
        </p>
        <p>
          <strong>Prompt Investigation:</strong> A dedicated team promptly investigates all reports,
          reviewing user profiles, messages, and other data.<br></br>
          <strong>Evidence Preservation:</strong> Relevant information, including usernames,
          timestamps, and content, is preserved.<br></br>
          <strong>Law Enforcement Notification:</strong> We contact appropriate law enforcement
          agencies (local, state, and federal) as needed<br></br>
          <strong>Full Cooperation:</strong> We fully cooperate with law enforcement investigations.
          <br></br>
          <strong>Record Keeping:</strong> All reports, investigations, and communications are
          documented and retained for at least three years.
        </p>
        <h3>6. Policy Review and Updates:</h3>
        <p>
          This policy undergoes annual review and updates to reflect changes in applicable laws,
          best practices, and user feedback. The effective date of any changes is clearly noted.
        </p>
        <h3>7. Contact Us:</h3>
        <p>Contact us with questions or concerns at support@localflirt.com or +1 877-381-0324.</p>
        <h3>Conclusion:</h3>
        <p>
          LocalFlirt.com is committed to a safe and ethical online environment free from human
          trafficking. We will continually refine our policies and procedures to meet the highest
          standards.
        </p>
      </div>
    </div>
  );
};

export default AntiHumanTrafficingPolicy;
