import './MainView.scss';

import { Location, Router } from '@reach/router';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Zendesk, { ZendeskAPI } from 'react-zendesk';
import { useRecoilValue } from 'recoil';

import { addUserData, setUserLoading } from '../../actions/userActions/userActions';
import { useGetUserData } from '../../core/APIHooks/userData/useGetUserData';
import useInterstitialAdZone from '../../hooks/PopUnder/interstitial';
import usePopUnder from '../../hooks/PopUnder/usePopUnder';
import { useDeviceType } from '../../hooks/useDeviceType';
import { showPopUnder } from '../../recoil/atoms/showPopUnder';
import { setMobileView } from '../../redux/actions/welcomeMessage';
import { setZendeskWidget } from '../../redux/actions/zendesk';
import { authMe } from '../../services/auth-service/auth.service';
import { showEmailVerificationInstruction } from '../../services/navigation-service/navigation.service';
import { getToken, hasTokenExpired } from '../../services/token-service/token.service';
import {
  getUserData,
  setUserLocalData,
  shouldShowInterstitialAd
} from '../../services/user-service/user.service';
import { shouldShowElement } from '../../util/banners';
import { SpecialOfferModal } from '../../V2/components/special-offer-modal';
import { ROUTES } from '../routes';
import HomePageMain from './components/HomePageMain/HomePageMain';
import { MEMBERS_ROUTES } from './components/MembersAreaMain/members_routes.js';
import MembersAreaMain from './components/MembersAreaMain/MembersAreaMain';

class OnRouteChangeWorker extends React.Component {
  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.props.action();
    }
  }

  render() {
    return null;
  }
}

const hasWindow = typeof window !== 'undefined';
const getWindowDimensions = () => {
  return hasWindow ? window.innerWidth : null;
};

const MainView = () => {
  const deviceType = useDeviceType();
  const userData = getUserData();
  const { ad_zone_settings } = userData || {};
  const shouldShowPopUnder = useRecoilValue(showPopUnder);

  const isPopUnderEnabled = useMemo(
    () =>
      shouldShowElement({
        device: deviceType,
        showBannerMobile: ad_zone_settings?.adzonePopunderMobileActive,
        showBannerDesktop: ad_zone_settings?.adzonePopunderWebActive
      }),
    [deviceType, ad_zone_settings]
  );

  usePopUnder(isPopUnderEnabled);

  const isInterstitialEnabled = useMemo(
    () =>
      shouldShowInterstitialAd() &&
      shouldShowElement({
        device: deviceType,
        showBannerMobile: ad_zone_settings?.adzoneInterstitialMobileActive,
        showBannerDesktop: ad_zone_settings?.adzoneInterstitialWebActive
      }),
    [deviceType, ad_zone_settings]
  );

  useInterstitialAdZone(isInterstitialEnabled);

  const dispatch = useDispatch();
  const zendesk = useSelector((state) => state.zendeskWidget);
  const { data } = useGetUserData();
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  const OnRouteChange = ({ action }) => (
    <Location>
      {({ location }) => <OnRouteChangeWorker location={location} action={action} />}
    </Location>
  );

  useEffect(() => {
    if (!hasWindow) return;
    const handleResize = () => setWindowDimensions(getWindowDimensions());
    dispatch(setMobileView(windowDimensions));
    window.addEventListener('resize', handleResize);
    return () => window.addEventListener('resize', handleResize);
  }, [windowDimensions]);

  useEffect(() => {
    dispatch(setUserLoading(true));
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const verified = urlParams.get('verified');

    const authMeFailure = (error) => {
      dispatch(setUserLoading(false));
    };

    const authMeSuccess = (response) => {
      const { data } = response.data;
      const now = moment(new Date());
      const created = moment(response?.data?.data?.created_at);
      const duration = moment.duration(now.diff(created));
      const hours = duration.asHours();
      setUserLocalData(response.data);
      dispatch(addUserData(data));
      dispatch(setUserLoading(false));
      if (
        response?.data?.data?.click_id &&
        !response?.data?.data?.email_verified_at &&
        !response?.data?.data?.auto_verified_at &&
        Math.round(hours) < 48 &&
        !verified
      ) {
        showEmailVerificationInstruction();
      }
    };

    if (getToken() || hasTokenExpired()) {
      authMe().then(authMeSuccess).catch(authMeFailure);
    }
  }, [dispatch]);

  const [widget, setWidget] = useState(false);

  const checkShowWidget = useCallback(
    (word) => {
      if (word) {
        if (
          window.location.pathname !== '/contact' ||
          window.location.pathname !== '/members-area/contact-us'
        ) {
          ZendeskAPI('webWidget', 'hide');
          dispatch(setZendeskWidget(false));
        }

        if (
          window.location.pathname === '/contact' ||
          window.location.pathname === '/members-area/contact-us'
        ) {
          ZendeskAPI('webWidget', 'show');
          dispatch(setZendeskWidget(true));
        }
      }
    },
    [dispatch]
  );
  useEffect(() => {
    // dispatch(setZendeskWidget(false));
    // ZendeskAPI('webWidget', 'hide');
    checkShowWidget(widget);
  }, [widget, checkShowWidget]);

  const ZENDESK_KEY = 'aee0ba86-fa16-4920-ab02-d2acaaccc3c6';

  const setting = {
    color: {
      theme: '#9F0220'
    },
    launcher: {
      chatLabel: {
        'en-US': 'Need Help'
      }
    }
  };

  const startWidget = () => {
    setWidget(true);
  };

  useEffect(() => {
    // HOTFIX trigger on every render
    if (window?.fcWidget && location.pathname !== '/members-area/contact-us') {
      window?.fcWidget?.hide();
    }
  });

  return (
    <div className="MainView">
      <SpecialOfferModal />
      <Router primary={false}>
        <HomePageMain path={`${ROUTES.ROOT}/*`} />
        <MembersAreaMain path={`${MEMBERS_ROUTES.MEMBERSAREA}/*`} />
      </Router>
      <OnRouteChange
        action={() => {
          window.scrollTo(0, 0);
        }}
      />
      {zendesk?.zendeskWidget && (
        <Zendesk defer zendeskKey={ZENDESK_KEY} onLoaded={() => startWidget()} {...setting} />
      )}
    </div>
  );
};

export default MainView;
