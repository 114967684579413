import './style.scss';

import { LoadingOutlined } from '@ant-design/icons';
import { Modal, Spin } from 'antd';
import { useEffect } from 'react';

export const ContinueChattingV1 = ({
  open,
  onCancel,
  openCreditsModal,
  handleSend,
  isLoading
}: {
  open: boolean;
  onCancel: () => void;
  openCreditsModal: (open: boolean) => void;
  handleSend: (selectedPackageId?: number) => void;
  isLoading: boolean;
}) => {
  const style: React.CSSProperties = {
    display: 'flex',
    justifyContent: 'center',
    padding: 20,
    gap: 20,
    flexDirection: 'column',
    alignItems: 'center'
  };

  useEffect(() => {
    if (open) {
      sessionStorage.setItem('continueChatModalOpen', 'true');
    }

    const shouldClose = sessionStorage.getItem('closeAfterPurchase') === 'true';
    if (shouldClose && open) {
      sessionStorage.removeItem('closeAfterPurchase');
      onCancel();
    }
  }, [open, onCancel]);

  return (
    <div>
      <Modal
        width={'45%'}
        className={'payment-experiment-modal payment-experiment-modal-continue-chatting-v1'}
        footer={null}
        bodyStyle={style}
        maskClosable={!isLoading}
        mask
        open={open}
        onCancel={() => {
          if (!isLoading) {
            sessionStorage.removeItem('continueChatModalOpen');
            onCancel();
          }
        }}
        closable={!isLoading}
        zIndex={999}
      >
        {isLoading ? (
          <div className="loading-overlay">
            <Spin indicator={<LoadingOutlined style={{ fontSize: 36, color: '#9f0220' }} spin />} />
            <p>
              Please wait while we process your payment.
              <br />
              Do not close this page.
            </p>
          </div>
        ) : (
          <>
            <h2>
              Oops! You've run out of credits. Click the button below to continue chatting at the
              same rate.
            </h2>
            <button
              className={'payment-experiment-modal-button'}
              onClick={() => handleSend(undefined)}
            >
              Continue Chatting
            </button>
            <span
              className={'payment-experiment-modal-description-wrapper'}
              onClick={() => openCreditsModal(true)}
            >
              <span className={'payment-experiment-modal-description-wrapper-or'}>or </span>
              <span className={'payment-experiment-modal-description-wrapper-text'}>
                Select a different bundle
              </span>
            </span>
          </>
        )}
      </Modal>
    </div>
  );
};
