export const LoadInboxSuccessIcon = ({ color = '#65D46E' }) => {
  return (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M24.125 7.65627H19.6042C18.7727 7.65627 18.771 8.94794 19.6042 8.94794H22.3735L14.5686 15.61C13.3696 16.6337 11.6297 16.633 10.4311 15.61L2.62651 8.94794H5.39584C6.22735 8.94794 6.22896 7.65627 5.39584 7.65627H0.875005C0.518505 7.65627 0.229172 7.9456 0.229172 8.3021V24.125C0.229172 24.4815 0.518505 24.7709 0.875005 24.7709H24.125C24.4815 24.7709 24.7708 24.4815 24.7708 24.125V8.3021C24.7708 7.9456 24.4815 7.65627 24.125 7.65627ZM23.4792 22.7985L16.6527 17.4484C16.3724 17.2285 15.9662 17.2776 15.746 17.5585C15.5257 17.8391 15.5751 18.245 15.8561 18.4653L22.254 23.4792H2.74598L9.14361 18.4653C9.42422 18.245 9.47331 17.8394 9.2534 17.5585C9.03349 17.2776 8.62759 17.2282 8.34665 17.4484L1.52084 22.7982V9.70259L9.59279 16.5927C11.2539 18.0106 13.7465 18.0103 15.4075 16.5927L23.4792 9.70259V22.7985ZM12.5 11.2084C15.527 11.2084 17.9896 8.74579 17.9896 5.71877C17.9896 2.69175 15.527 0.229187 12.5 0.229187C9.47299 0.229187 7.01042 2.69175 7.01042 5.71877C7.01042 8.74579 9.47299 11.2084 12.5 11.2084ZM12.5 1.52085C14.8147 1.52085 16.6979 3.4041 16.6979 5.71877C16.6979 8.03344 14.8147 9.91669 12.5 9.91669C10.1853 9.91669 8.30209 8.03344 8.30209 5.71877C8.30209 3.4041 10.1853 1.52085 12.5 1.52085ZM10.1059 6.49829C9.8537 6.24609 9.8537 5.83728 10.1059 5.58508C10.3578 5.33288 10.7672 5.33288 11.0191 5.58508L11.8264 6.39237L13.9544 3.99795C14.1914 3.73154 14.5993 3.70732 14.8663 3.94434C15.1327 4.18136 15.1566 4.58953 14.9199 4.85626L12.3366 7.76251C12.2184 7.89523 12.0508 7.9737 11.8729 7.97886C11.6992 7.98758 11.5193 7.91202 11.3972 7.78996L10.1059 6.49829Z"
        fill={color}
      />
    </svg>
  );
};
