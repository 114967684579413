import ANALSEX from '../../../assets/images/InterestsBadges/anal_sex.png';
import BLINDFOLDS from '../../../assets/images/InterestsBadges/blindfolds.svg';
import CREMPIE from '../../../assets/images/InterestsBadges/crempie.svg';
import CUDDLES from '../../../assets/images/InterestsBadges/cuddles.svg';
import DIRTYTALK from '../../../assets/images/InterestsBadges/dirty_talk.svg';
import EROTICMASSAGE from '../../../assets/images/InterestsBadges/erotic_massage.png';
import EXCHANGEPICTURE from '../../../assets/images/InterestsBadges/exchange_pictures.png';
import FLIRTING from '../../../assets/images/InterestsBadges/flirting.svg';
import INTIMATETALKS from '../../../assets/images/InterestsBadges/intimate_talks.svg';
import KISSING from '../../../assets/images/InterestsBadges/kissing.svg';
import LINGERIE from '../../../assets/images/InterestsBadges/lingerie.svg';
import MAKINGHOMEMOVIES from '../../../assets/images/InterestsBadges/making_home_movies.svg';
import ORALSEX from '../../../assets/images/InterestsBadges/oral_sex.png';
import PUBLICSEX from '../../../assets/images/InterestsBadges/public_sex.png';
import ROMANTICDINNER from '../../../assets/images/InterestsBadges/romantic_dinner.svg';
import ROUGHSEX from '../../../assets/images/InterestsBadges/rough_sex.svg';
import SAFESEX from '../../../assets/images/InterestsBadges/safe_sex.png';
import SUGARDATINGG from '../../../assets/images/InterestsBadges/sugar_dating.svg';
import TATTOOS from '../../../assets/images/InterestsBadges/tattoos.svg';
import THREESOME from '../../../assets/images/InterestsBadges/threesome.png';
import TOYS from '../../../assets/images/InterestsBadges/toys.svg';

export const interestsObject = {
  kissing: {
    title: 'Kissing',
    image: KISSING
  },
  'exchanging pictures': {
    title: 'Exchanging Pictures',
    image: EXCHANGEPICTURE
  },
  'safe sex': {
    title: 'Safe Sex',
    image: SAFESEX
  },
  'erotic massage': {
    title: 'Erotic Massage',
    image: EROTICMASSAGE
  },
  lingerie: {
    title: 'Lingerie',
    image: LINGERIE
  },
  'oral sex': {
    title: 'Oral Sex',
    image: ORALSEX
  },
  threesome: {
    title: 'Threesome',
    image: THREESOME
  },
  'anal sex': {
    title: 'Anal Sex',
    image: ANALSEX
  },
  'public sex': {
    title: 'Public Sex',
    image: PUBLICSEX
  },
  blindfolds: {
    title: 'BlindFolds',
    image: BLINDFOLDS
  },
  creampie: {
    title: 'Creampie',
    image: CREMPIE
  },
  cuddles: {
    title: 'Cuddles',
    image: CUDDLES
  },
  'dirty talk': {
    title: 'Dirty Talk',
    image: DIRTYTALK
  },
  flirting: {
    title: 'Flirting',
    image: FLIRTING
  },
  'intimate talks': {
    title: 'Intimate Talks',
    image: INTIMATETALKS
  },
  'making home movies': {
    title: 'Making Home Movies',
    image: MAKINGHOMEMOVIES
  },
  'romantic dinner': {
    title: 'Romantic Dinner',
    image: ROMANTICDINNER
  },
  'rough sex': {
    title: 'Rough Sex',
    image: ROUGHSEX
  },
  'sugar dating': {
    title: 'Suggar Dating',
    image: SUGARDATINGG
  },
  tattoos: {
    title: 'Tattoos',
    image: TATTOOS
  },
  toys: {
    title: 'Toys',
    image: TOYS
  }
};
