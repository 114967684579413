import './style.scss';

import { FrownOutlined } from '@ant-design/icons';
import { useLocation, useNavigate } from '@reach/router';
import { Pagination, Result } from 'antd';
import { useEffect } from 'react';

import Button from '../../../../../../components/Button/Button';
import LoaderComponent from '../../../../../../components/LoaderCompoment/LoaderComponent';
import { ProfileCard } from '../../../../../../components/ProfileCard/ProfileCard';
import { useGetVisitors } from '../../../../../../core/APIHooks/useGetVisitors';
import { useMarkVisitorsNotificationAsRead } from '../../../../../../core/APIHooks/useMarkVisitorsNotificationAsRead';
import { useGetUserData } from '../../../../../../core/APIHooks/userData/useGetUserData';
import { useAdZoneEnabled } from '../../../../../../hooks/PopUnder/useAdZoneEnabled';
import { Triggers } from '../../../../../../V2/model/Triggers';
import { MEMBERS_ROUTES } from '../../members_routes';

const Visitors = (props) => {
  const perPage = 30;
  const location = useLocation();
  const navigate = useNavigate();
  const { data: user, isLoading: gettingUser, dataUpdatedAt } = useGetUserData();
  const { ad_zone_settings } = user?.data || {};
  const { visitors, setVisitors } = props;
  const query = new URLSearchParams(location.search);
  const { data, isLoading } = useGetVisitors({ page: query.get('page') ?? '1', perPage });
  const { mutate: markVisitors } = useMarkVisitorsNotificationAsRead();
  const premium = user?.data?.hasPremiumSubscription;
  const handleQuery = (param, value) => {
    query.set(param, value);
    navigate(`${location.pathname}?${query.toString()}`);
  };

  useEffect(() => {
    if (visitors > 0 && user?.data?.hasPremiumSubscription) {
      setVisitors();
      markVisitors();
    }
  }, [markVisitors, setVisitors, user?.data?.hasPremiumSubscription, visitors]);
  useEffect(() => {
    if (dataUpdatedAt > 0 && !premium && user?.data?.id) {
      navigate(`${MEMBERS_ROUTES.MEMBERSAREA}${MEMBERS_ROUTES.MEMBERSPRICING}`, {
        state: {
          trigger: Triggers.VISITORS,
          id: undefined
        }
      });
    }
  }, [gettingUser, premium]);

  const renderProfiles = () => {
    return (
      data?.data.length > 0 &&
      premium &&
      data.data.map((item) => (
        <div className={'container'} key={item.id}>
          <ProfileCard redirectFrom="visitors" profile={item} />
        </div>
      ))
    );
  };

  const adZoneSettings = {
    headerMobileActive: ad_zone_settings?.adzoneHeaderVisitorsMobileActive,
    headerWebActive: ad_zone_settings?.adzoneHeaderVisitorsWebActive,
    footerMobileActive: ad_zone_settings?.adzoneFooterVisitorsMobileActive,
    footerWebActive: ad_zone_settings?.adzoneFooterVisitorsWebActive
  };

  const { isAdzoneHeaderEnabled, isAdzoneFooterEnabled } = useAdZoneEnabled(adZoneSettings);

  return (
    <>
      {isAdzoneHeaderEnabled && (
        <div
          id="header-adzone-container-visitors"
          className="Page-AdZone Page-AdZone--Header"
        ></div>
      )}
      <div className="title"> Visitors </div>
      {isLoading || gettingUser ? (
        <div className={'loader'}>
          <LoaderComponent />
        </div>
      ) : (
        <>
          {data?.data.length === 0 ? (
            <div className={'no-result'}>
              <Result
                icon={<FrownOutlined style={{ color: '#9f0220' }} />}
                title="No Visitors yet!"
                subTitle="Try sending some messages"
                extra={
                  <Button
                    text="Browse profiles?"
                    onClick={() => navigate(`${MEMBERS_ROUTES.MEMBERSAREA}`)}
                  />
                }
              />
            </div>
          ) : (
            <div className={'grid-container'}>{renderProfiles()}</div>
          )}
        </>
      )}
      {isAdzoneFooterEnabled && (
        <div
          id="footer-adzone-container-visitors"
          className="Page-AdZone Page-AdZone--Footer"
        ></div>
      )}
      {!isLoading && (
        <div className={'pagination'}>
          <Pagination
            className={'favouritePagination'}
            defaultCurrent={1}
            current={data?.meta.current_page}
            total={data?.meta.total}
            pageSize={perPage}
            onChange={(page) => handleQuery('page', page)}
            showSizeChanger={false}
            hideOnSinglePage={true}
          />
        </div>
      )}
    </>
  );
};

export default Visitors;
