import { Router, useLocation, useNavigate } from '@reach/router';
import moment from 'moment';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSetRecoilState } from 'recoil';

import BasicFooter from '../../../../components/BasicFooter/BasicFooter';
import BasicHeader from '../../../../components/BasicHeader/BasicHeader';
import PageNotFound from '../../../../components/PageNotFound/PageNotFound';
import { userData as userDataNew } from '../../../../recoil/atoms/userDataAtom';
import { showMembersArea } from '../../../../redux/actions/authentication';
import { showHideWelcomeMessage } from '../../../../redux/actions/welcomeMessage';
import { autoLogIn } from '../../../../services/auth-service/auth.service';
import {
  showEmailVerificationInstruction,
  showMembersAreaPage
} from '../../../../services/navigation-service/navigation.service';
import {
  getToken,
  hasTokenExpired,
  saveToken
} from '../../../../services/token-service/token.service';
import { cleanupUserData, getUserData } from '../../../../services/user-service/user.service';
import { ROUTES } from '../../../routes';
import EmailVerificationInstruction from '../EmailVerificationInstruction/EmailVerificationInstruction';
import AboutUs from '../FooterPages/AboutUs/AboutUs';
import AntiHumanTrafficingPolicy from '../FooterPages/AntiHumanTrafficingPolicy/AntiHumanTrafficingPolicy';
import ContactUs from '../FooterPages/ContactUs/ContactUs';
import FAQ from '../FooterPages/FAQ/FAQ';
import NonConsensualParticipationPolicy from '../FooterPages/NonConsensualParticipationPolicy/NonConsensualParticipationPolicy';
import Pricing from '../FooterPages/Pricing/Pricing';
import PrivacyPolicy from '../FooterPages/PrivacyPolicy/PrivacyPolicy';
import TermsAndConditions from '../FooterPages/TermsAndConditions/TermsAndConditions';
import LanderConfirmationPage from '../LanderConfirmationPage/LanderConfirmationPage';
import LanderWrapper from '../LanderWrapper/LanderWrapper';
import { showMessagesPage } from '../MembersAreaMain/navigation.service';
import SignUpAff from '../SignUpAff/SignUpAff';
import SignUpAff2 from '../SignUpAff2/SignUpAff2';
import SignUpAff3 from '../SignUpAff3/SignUpAff3';
import SignUpAff4 from '../SignUpAff4/SignUpAff4';
import SignUpAff5 from '../SignUpAff5/SignUpAff5';
import SignUpAff5sso from '../SignUpAff5sso/SignUpAff5sso';
import SignUpAff6NoZip from '../SignUpAff6NoZip/SignUpAff6NoZip';
import SignUpAff7 from '../SignUpAff7/SignUpAff7';
import SignUpAff7nosso from '../SignUpAff7nosso/SignUpAff7nosso';
import SignUpAff8 from '../SignUpAff8/SignUpAff8';
import HomePage from './HomePage/HomePage';

const HomePageMain = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { authentication } = useSelector((state) => state);
  const dispatch = useDispatch();
  const userData = getUserData();
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const action = urlParams.get('action');
  const setUserData = useSetRecoilState(userDataNew);
  let now = moment(new Date());
  const userCreatedAgo = now.diff(userData?.created_at, 'days');

  useEffect(() => {
    dispatch(showHideWelcomeMessage(true));
  }, []);

  useEffect(() => {
    const affiliateLock = localStorage.getItem('affiliateSiteLockdown');
    const affLockdown = moment(affiliateLock).isAfter(moment().subtract(1440, 'minutes'));

    if (location.pathname.includes('/signup') && userData && !affLockdown) {
      if (userCreatedAgo <= 60) {
        showMessagesPage();
      } else if (getToken()) {
        navigate(ROUTES.MEMBERSAREA);
      } else {
        navigate('/not-found');
      }
    }

    if (affLockdown) {
      showEmailVerificationInstruction();
    }
    if (
      getToken() &&
      !hasTokenExpired() &&
      userData &&
      !location.pathname.includes('signup') &&
      location.pathname !== '/not-found' &&
      location.pathname !== '/email-verification-instruction'
    ) {
      showMembersAreaPage();
      dispatch(showMembersArea(true));
    } else if (location.pathname == '/email-verification-instruction') {
      if (userData?.location_confirmed) {
        showMembersAreaPage();
        dispatch(showMembersArea(true));
      }
    } else if (action === 'login') {
      const idData = urlParams.get('id');
      const hashData = urlParams.get('hash');
      const source = urlParams.get('source');

      if (!hashData) {
        return;
      }

      cleanupUserData();
      autoLogIn(idData, hashData, source)
        .then((res) => {
          saveToken(res.data.access_token, res.data.expires_in);
          localStorage.setItem('userData', JSON.stringify(res.data.user));
          setUserData(JSON.stringify(res.data.user));
        })
        .then(() => {
          showMembersAreaPage();
        })
        .catch((err) => console.log(err));
    }
    var elmnt = document.getElementById('scrollTop');
    elmnt.scrollIntoView();
  }, [props.location.pathname]);
  return (
    <div id="scrollTop" className="HomePageMain">
      {location.pathname.includes('signup') ||
        (location.pathname !== '/not-found' && <BasicHeader />)}
      <Router primary={false}>
        <HomePage path={`${ROUTES.ROOT}`} />
        <AboutUs path={`${ROUTES.ABOUT}`} />
        <Pricing path={`${ROUTES.PRICING}`} />
        <FAQ path={`${ROUTES.FAQ}`} />
        <ContactUs path={`${ROUTES.CONTACT}`} />
        <PrivacyPolicy path={`${ROUTES.PRIVACYPOLICY}`} />
        <TermsAndConditions path={`${ROUTES.TERMS}`} />
        <AntiHumanTrafficingPolicy path={`${ROUTES.ANTIHUMANTRAFICKING}`} />
        <NonConsensualParticipationPolicy path={`${ROUTES.NONCONSENSUALPARTICIPATION}`} />
        <SignUpAff path={`${ROUTES.SIGNUP}`} />
        <SignUpAff2 path={`${ROUTES.SIGNUP2}`} />
        <SignUpAff3 path={`${ROUTES.SIGNUP3}`} />
        <SignUpAff6NoZip path={`${ROUTES.SIGNUP6NOZIP}`} />
        <SignUpAff4 path={`${ROUTES.SIGNUP4}`} />
        <SignUpAff5 path={`${ROUTES.SIGNUP5}`} />
        <SignUpAff5sso path={`${ROUTES.SIGNUP5SSO}`} />
        <SignUpAff7 path={`${ROUTES.SIGNUP7}`} />
        <SignUpAff7nosso path={`${ROUTES.SIGNUP7NOSSO}`} />
        <SignUpAff8 path={`${ROUTES.SIGNUP8}`} />
        <LanderWrapper path={`${ROUTES.LANDERS}/:landerId/*/*`} />
        <LanderConfirmationPage path={`${ROUTES.SIGNUP}/api/regconfirm`} />
        <EmailVerificationInstruction path={`${ROUTES.EMAILVERIFICATIONINSTRUCTION}`} />
        <PageNotFound path={`${ROUTES.NOTFOUND}`} />
      </Router>
      {location.pathname.includes('signup') ||
        (location.pathname !== '/not-found' && <BasicFooter />)}
    </div>
  );
};

export default HomePageMain;
