export const ArchivedConversationIcon = () => {
  return (
    <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M21.875 24.0625C21.5849 24.0625 21.3067 24.1777 21.1016 24.3829C20.8965 24.588 20.7812 24.8662 20.7812 25.1562C20.7812 25.4463 20.666 25.7245 20.4609 25.9296C20.2558 26.1348 19.9776 26.25 19.6875 26.25H15.3125C15.0224 26.25 14.7442 26.1348 14.5391 25.9296C14.334 25.7245 14.2188 25.4463 14.2188 25.1562C14.2188 24.8662 14.1035 24.588 13.8984 24.3829C13.6933 24.1777 13.4151 24.0625 13.125 24.0625C12.8349 24.0625 12.5567 24.1777 12.3516 24.3829C12.1465 24.588 12.0313 24.8662 12.0313 25.1562C12.0312 26.0265 12.377 26.8611 12.9923 27.4764C13.6077 28.0918 14.4423 28.4375 15.3125 28.4375H19.6875C20.5577 28.4375 21.3923 28.0918 22.0077 27.4764C22.623 26.8611 22.9688 26.0265 22.9688 25.1562C22.9688 24.8662 22.8535 24.588 22.6484 24.3829C22.4433 24.1777 22.1651 24.0625 21.875 24.0625Z"
        fill="#C6B7B8"
      />
      <path
        d="M33.8341 16.0136L28.9034 3.19703C28.6629 2.57966 28.2422 2.04893 27.696 1.67383C27.1499 1.29873 26.5035 1.09661 25.8409 1.09375H9.15906C8.4965 1.09661 7.85012 1.29873 7.30395 1.67383C6.75778 2.04893 6.33709 2.57966 6.09656 3.19703L1.16594 16.0136C1.12176 16.14 1.09741 16.2724 1.09375 16.4062V30.625C1.09375 31.4952 1.43945 32.3298 2.05481 32.9452C2.67016 33.5605 3.50476 33.9062 4.375 33.9062H30.625C31.4952 33.9062 32.3298 33.5605 32.9452 32.9452C33.5605 32.3298 33.9062 31.4952 33.9062 30.625V16.4062C33.9065 16.272 33.882 16.1389 33.8341 16.0136ZM8.1375 3.98344C8.21763 3.77739 8.35792 3.60022 8.54012 3.47498C8.72231 3.34975 8.93798 3.28224 9.15906 3.28125H25.8409C26.0619 3.28231 26.2774 3.34971 26.4595 3.47472C26.6417 3.59973 26.7821 3.77658 26.8625 3.98234L31.22 15.3125H25.2328C24.6919 15.3118 24.1592 15.4449 23.6822 15.6998C23.2051 15.9548 22.7985 16.3237 22.4984 16.7738L21.6103 18.107C21.5108 18.2562 21.3762 18.3786 21.2183 18.4634C21.0603 18.5483 20.884 18.5931 20.7047 18.5938H14.2953C14.1153 18.5938 13.938 18.5494 13.7793 18.4645C13.6206 18.3796 13.4852 18.2568 13.3853 18.107L12.4972 16.7738C12.1976 16.3243 11.7916 15.9558 11.3154 15.7008C10.8392 15.4459 10.3074 15.3125 9.76719 15.3125H3.78L8.1375 3.98344ZM31.7188 30.625C31.7188 30.9151 31.6035 31.1933 31.3984 31.3984C31.1933 31.6035 30.9151 31.7188 30.625 31.7188H4.375C4.08492 31.7188 3.80672 31.6035 3.6016 31.3984C3.39648 31.1933 3.28125 30.9151 3.28125 30.625V17.5H9.76719C9.94721 17.5 10.1245 17.5444 10.2832 17.6293C10.4419 17.7142 10.5773 17.837 10.6772 17.9867L11.5653 19.32C11.8644 19.7699 12.2703 20.1388 12.7466 20.3938C13.223 20.6488 13.755 20.7819 14.2953 20.7812H20.7047C21.2457 20.7826 21.7787 20.6499 22.2558 20.3949C22.733 20.1399 23.1395 19.7706 23.4391 19.32L24.3272 17.9867C24.4267 17.8376 24.5613 17.7152 24.7192 17.6303C24.8772 17.5454 25.0535 17.5007 25.2328 17.5H31.7188V30.625Z"
        fill="#C6B7B8"
      />
      <path
        d="M9.84375 13.125H25.1562C25.4463 13.125 25.7245 13.0098 25.9296 12.8046C26.1348 12.5995 26.25 12.3213 26.25 12.0312C26.25 11.7412 26.1348 11.463 25.9296 11.2579C25.7245 11.0527 25.4463 10.9375 25.1562 10.9375H9.84375C9.55367 10.9375 9.27547 11.0527 9.07035 11.2579C8.86523 11.463 8.75 11.7412 8.75 12.0312C8.75 12.3213 8.86523 12.5995 9.07035 12.8046C9.27547 13.0098 9.55367 13.125 9.84375 13.125Z"
        fill="#C6B7B8"
      />
      <path
        d="M10.9375 8.75H24.0625C24.3526 8.75 24.6308 8.63477 24.8359 8.42965C25.041 8.22453 25.1562 7.94633 25.1562 7.65625C25.1562 7.36617 25.041 7.08797 24.8359 6.88285C24.6308 6.67773 24.3526 6.5625 24.0625 6.5625H10.9375C10.6474 6.5625 10.3692 6.67773 10.1641 6.88285C9.95898 7.08797 9.84375 7.36617 9.84375 7.65625C9.84375 7.94633 9.95898 8.22453 10.1641 8.42965C10.3692 8.63477 10.6474 8.75 10.9375 8.75Z"
        fill="#C6B7B8"
      />
    </svg>
  );
};
