/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useEffect, useMemo } from 'react';

import { cleanString } from '../helpers/cleanString';
import { FreshchatPlatform } from '../model/enum/FreshchatPlatform.enum';
import { useUpdateUserFreshChatRestoreId } from '../services/myProfile/useUpdateUserFreshChatRestoreId';

declare global {
  interface Window {
    fcWidget: any;
    fcWidgetMessengerConfig: any;
  }
}

type FreshChatConfig = {
  mode: 'auto' | 'manual';
  autoShowDelay?: number;
};

export const useFreshChat = (config?: FreshChatConfig) => {
  const mode = config?.mode ?? 'auto';
  const autoShowDelay = config?.autoShowDelay ?? 5000;

  const rawUserData = localStorage.getItem('userData');
  const userData = rawUserData ? JSON.parse(rawUserData) : null;
  const hasSpentBefore = useMemo(
    () => userData?.has_spent_before ?? false,
    [userData?.has_spent_before]
  );
  const freshchatPlatform = useMemo(
    () => userData?.freshdesk_platform ?? FreshchatPlatform.WIDGET,
    [userData?.freshdesk_platform]
  );
  const { mutate: saveRestoreId } = useUpdateUserFreshChatRestoreId();
  const restoreId = userData?.freshchat_restore_id ?? null;

  useEffect(() => {
    const platform = cleanString(freshchatPlatform);
    const isContactPage = window.location.pathname === '/members-area/contact-us';
    const isVerificationPage = window.location.pathname === '/email-verification-instruction';
    const isAllowedPage = isContactPage || isVerificationPage;

    let timeoutId: NodeJS.Timeout | null = null;

    // Hide widget on non-allowed pages
    if (!isAllowedPage) {
      if (window.fcWidget) {
        window.fcWidget.close();
        window.fcWidget.hide();
      }
      return () => {
        if (window.fcWidget) {
          window.fcWidget.close();
          window.fcWidget.hide();
        }
      };
    }

    let widgetLoadedHandler: any = null;

    // Hide widget initially:
    // - On verification page (always starts hidden, requires button click)
    // - On contact page when in manual mode
    if (window.fcWidget && (isVerificationPage || (isContactPage && mode === 'manual'))) {
      window.fcWidget.close();
      window.fcWidget.hide();
    }

    // Initialize widget if:
    // - On verification page (always allowed)
    // - On contact page (requires hasSpentBefore and correct platform)
    if (
      window.fcWidget &&
      (isVerificationPage ||
        (isContactPage && hasSpentBefore && platform === FreshchatPlatform.CHAT))
    ) {
      // Set up widget loaded handler for verification page and manual mode contact page
      if (isVerificationPage || (isContactPage && mode === 'manual')) {
        widgetLoadedHandler = function () {
          window.fcWidget.hide();
          window.fcWidget.close();
        };
        window.fcWidget.on('widget:loaded', widgetLoadedHandler);
      }

      window.fcWidget.init({
        token: '98a0a2f9-e360-495e-9f36-c88b0addb4ee',
        host: 'https://cdncloud-help.freshchat.com',
        widgetUuid: 'd4767699-f7df-417d-8031-22f6e2cad441',
        externalId: userData?.id ?? 'Unknown ID',
        restoreId,
        firstName: userData?.profile?.username ?? 'Unknown name',
        email: userData?.email ?? 'Unknown email'
      });

      window.fcWidget.on('user:created', async (resp: any) => {
        const { status, data } = resp;
        if (status === 200 && data.restoreId && userData?.id) {
          saveRestoreId(data.restoreId);
        }

        // Only hide on initial load, not after user interaction
        if (
          (isVerificationPage || (isContactPage && mode === 'manual')) &&
          !window.fcWidget.isOpen()
        ) {
          window.fcWidget.hide();
          window.fcWidget.close();
        }
      });

      // Auto-show widget ONLY on contact page in auto mode
      if (isContactPage && mode === 'auto') {
        timeoutId = setTimeout(() => {
          window.fcWidget.show();
          window.fcWidget.open();
        }, autoShowDelay);
      }
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }

      if (window.fcWidget) {
        if (widgetLoadedHandler) {
          window.fcWidget.off('widget:loaded', widgetLoadedHandler);
        }
        window.fcWidget.off('user:created');
        window.fcWidget.close();
        window.fcWidget.hide();

        setTimeout(() => {
          if (window.fcWidget) {
            window.fcWidget.close();
            window.fcWidget.hide();
          }
        }, 100);
      }
    };
  }, [
    mode,
    autoShowDelay,
    userData?.id,
    hasSpentBefore,
    freshchatPlatform,
    restoreId,
    saveRestoreId
  ]);

  const showWidget = useCallback(() => {
    if (!window.fcWidget) {
      return;
    }

    window.fcWidget.off('widget:loaded');
    window.fcWidget.show();
    setTimeout(() => {
      window.fcWidget.open();
    }, 50);
  }, []);

  return { showWidget };
};
