import './HeaderMembersArea.scss';

import { CloseOutlined } from '@ant-design/icons';
import { useLocation } from '@reach/router';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { IoChatbubbleOutline } from 'react-icons/io5';
import { useDispatch, useSelector } from 'react-redux';
import { useRecoilValue } from 'recoil';

import { queryClient } from '../../../../../../App';
import LogoText from '../../../../../../assets/images/MembersAreaNavbar/LogoText.svg';
import ProfileAvatar from '../../../../../../assets/images/MembersAreaNavbar/ProfileAvatar.svg';
import ImageN from '../../../../../../components/ImageN/ImageN';
import ModalDialog from '../../../../../../components/modals/ModalDialog/ModalDialog';
import PurchaseCredits from '../../../../../../components/modals/PurchaseCredits/PurchaseCredits';
import { key as userDataKey } from '../../../../../../core/APIHooks/userData/useGetUserData';
import { useDeviceType } from '../../../../../../hooks/useDeviceType';
import { useOutsideClick } from '../../../../../../hooks/useOutsideClick';
import { showPopUnder } from '../../../../../../recoil/atoms/showPopUnder';
import { getSidebar } from '../../../../../../redux/actions/sidebar';
import { CURRENT_USER_CREDITS } from '../../../../../../redux/types/credits';
import { SET_NOTIFICATIONS } from '../../../../../../redux/types/notifications';
import { SET_PROFILE_AVATAR } from '../../../../../../redux/types/profileAvatar';
import { markVisitorsNotificationAsRead } from '../../../../../../services/notification-service/notification-service';
import { getUserData } from '../../../../../../services/user-service/user.service';
import { shouldShowElement } from '../../../../../../util/banners';
import { myProfile, showMembersRootPage, showMessagesPage } from '../../navigation.service';
import SidebarData from './SidebarData';
import { SidebarItem } from './SidebarItem';

const HeaderMembersArea = (props) => {
  const { visitors, setVisitors } = props;
  const location = useLocation();
  const deviceType = useDeviceType();
  const purchaseCredits = useRef(null);
  const [windowWidth, getWindowWidth] = useState(window.innerWidth);
  const [sideBar, setSidebar] = useState(false);
  const data = queryClient.getQueryData(userDataKey); //getUserData();
  const shouldShowPopUnder = useRecoilValue(showPopUnder);
  const userData = data?.data;
  const { ad_zone_settings } = userData || {};
  const localData = getUserData();
  const dispatch = useDispatch();
  const { credits } = useSelector((state) => state);
  const { notifications } = useSelector((state) => state);
  const sidebarRef = useRef();
  useOutsideClick({ ref: sidebarRef, action: () => setSidebar(false) });
  useEffect(() => {
    if (visitors > 0 && window.location.pathname === '/members-area/visitors' && userData.premium) {
      markVisitorsNotificationAsRead()
        .then(() => {
          const dataFromLocalStorage = JSON.parse(localStorage.getItem('userData'));
          const newDataFromLocalStorage = {
            ...dataFromLocalStorage,
            unread_visitor_notifications: 0
          };
          localStorage.setItem('userData', JSON.stringify(newDataFromLocalStorage));
          setVisitors();
        })
        .catch((err) => console.log(err));
    }
  }, [window.location.pathname]);

  useEffect(() => {
    setSidebar(false);
  }, [location.pathname]);

  useEffect(() => {
    const messageNotifications = userData?.unread_message_notifications;
    userData && dispatch({ type: CURRENT_USER_CREDITS, payload: userData?.credits });
    dispatch({
      type: SET_NOTIFICATIONS,
      payload: Number(messageNotifications) || ''
    });
    dispatch({
      type: SET_PROFILE_AVATAR,
      payload: userData?.profile?.profile_image || ''
    });
  }, [dispatch, userData]);

  useEffect(() => {
    const handleResize = () => {
      getWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
  }, [windowWidth]);

  const sidebarToggle = () => {
    setSidebar(!sideBar);
  };

  const closeModal = (ref) => {
    ref.current.closeModal();
  };

  const mobileView = window.innerWidth;

  const openMyProfilePage = () => {
    myProfile();
    if (mobileView < 731) {
      getSidebar(false);
      sidebarToggle();
    }
  };
  const setAvatarPicture = () => {
    const userImage = userData?.profile?.profile_image;
    if (userImage) {
      return <ImageN name={userImage} />;
    }
    return <ImageN name={ProfileAvatar} />;
  };
  const [navbar, setNavbar] = useState(false);
  const changeBackground = () => {
    const scrollDiv = document.querySelector('.MainView');
    const offset = scrollDiv.scrollTop;
    if (offset >= 81) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };
  const redirectToHomepage = () => {
    document.body.style.overflow = 'hidden';
    document.body.style.position = 'fixed';
    document.body.style.touchAction = 'none';

    setTimeout(() => {
      document.body.style.overflow = '';
      document.body.style.position = '';
      document.body.style.touchAction = '';

      showMembersRootPage();
    }, 50);
  };

  useEffect(() => {
    const scrollDiv = document.querySelector('.MainView');
    scrollDiv.addEventListener('scroll', changeBackground);
    return () => {
      scrollDiv.removeEventListener('scroll', changeBackground);
    };
  });
  const displayUnreadMessages = (messages) => {
    if (messages < 0) return null;
    if (messages > 0 && messages < 100) return messages;
    if (messages >= 100) return '99+';
  };

  const adZoneConfigs = [
    { key: 'Cams', mobileKey: 'adzoneCamsMobileActive', desktopKey: 'adzoneCamsWebActive' },
    { key: 'Games', mobileKey: 'adzoneGamesMobileActive', desktopKey: 'adzoneGamesWebActive' },
    { key: 'Dating', mobileKey: 'adzoneDatingMobileActive', desktopKey: 'adzoneDatingWebActive' }
  ];

  const adZoneStates = useMemo(() => {
    return adZoneConfigs.reduce((acc, { key, mobileKey, desktopKey }) => {
      acc[`isAdZone${key}Enabled`] =
        shouldShowPopUnder &&
        shouldShowElement({
          device: deviceType,
          showBannerMobile: ad_zone_settings?.[mobileKey],
          showBannerDesktop: ad_zone_settings?.[desktopKey]
        });
      return acc;
    }, {});
  }, [deviceType, ad_zone_settings, shouldShowPopUnder]);

  const { isAdZoneCamsEnabled, isAdZoneGamesEnabled, isAdZoneDatingEnabled } = adZoneStates;

  return (
    <div>
      <div className={navbar ? 'navbar active  Navbar  ' : 'navbar Navbar '}>
        <div className="Hamburger" onClick={() => sidebarToggle()}>
          <div className="Burger" />
          <div className="Burger" />
          <div className="Burger" />
        </div>
        <div className="NavbarLogo-Holder">
          <img
            onContextMenu={(e) => e.preventDefault()}
            className={sideBar ? 'CenterImg' : null}
            onClick={() => redirectToHomepage()}
            src={LogoText}
            alt="logo-icon"
          />
        </div>
        <div className="Profile-Notification-Wrapper">
          <div onClick={() => showMessagesPage()} className="Notifications">
            <div className={'notification-holder'}>
              <div
                style={{
                  backgroundColor: notifications > 0 ? '#ea42d9' : 'transparent'
                }}
                className={'notification-number'}
              >
                {displayUnreadMessages(notifications)}
              </div>
              <IoChatbubbleOutline style={{ color: 'white', fontSize: '2rem' }} />
            </div>
          </div>
        </div>
      </div>
      <div ref={sidebarRef} className="SideBarWrapper">
        <div className={sideBar ? 'SideBar active ' : 'SideBar'}>
          <div className="Btn-ProfileInfo">
            <CloseOutlined onClick={() => setSidebar(false)} className="CloseSideBar" />
            <div className="Profile-Info">
              <div className="ProfilePhotoContainer" onClick={openMyProfilePage}>
                {setAvatarPicture()}
              </div>
              <p className="UserName" onClick={openMyProfilePage}>
                {userData?.username || localData?.username}
              </p>
              <p>
                Credits left:{' '}
                <span className={credits.currentUserCredits <= 5 ? 'LowCredits' : 'CreditsLeft'}>
                  {credits.currentUserCredits ? credits.currentUserCredits : '0'}
                </span>
              </p>
            </div>
          </div>
          <ul className="Sidebar-List">
            {SidebarData({ isAdZoneCamsEnabled, isAdZoneDatingEnabled, isAdZoneGamesEnabled }).map(
              (item, index) => (
                <SidebarItem
                  key={index}
                  item={item}
                  visitors={visitors}
                  notifications={notifications}
                  closeSidebar={() => sidebarToggle()}
                />
              )
            )}
          </ul>
        </div>
        {sideBar && <div className="CloseSideBar" onClick={() => sidebarToggle()} />}
      </div>
      <div className="PurchaseCreditsModalContainer">
        <ModalDialog ref={purchaseCredits} hideLogo={true}>
          <PurchaseCredits closeModal={closeModal} modalRef={purchaseCredits} />
        </ModalDialog>
      </div>
    </div>
  );
};

export default HeaderMembersArea;
