import axios from 'axios';

// const setCookie = (name, value, days) => {
//   const date = new Date();
//   date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
//   const expires = 'expires=' + date.toUTCString();
//   document.cookie = `${name}=${value};${expires};path=/`;
// };

// const getCookie = (name) => {
//   const nameEQ = name + '=';
//   const ca = document.cookie.split(';');
//   for (let i = 0; i < ca.length; i++) {
//     let c = ca[i];
//     while (c.charAt(0) === ' ') c = c.substring(1, c.length);
//     if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
//   }
//   return null;
// };

// const deleteCookie = (name) => {
//   document.cookie = `${name}=; Max-Age=-99999999;`;
// };

// Functions to use cookies
// const updateCookie = (cookieName, value, days) => {
//   setCookie(cookieName, value, days);
// };

// const removeCookie = (cookieName) => {
//   deleteCookie(cookieName);
// };

// const checkCookie = (cookieName, expectedValue) => {
//   const cookie = getCookie(cookieName);
//   return cookie === expectedValue;
// };

// export { checkCookie, deleteCookie, getCookie, removeCookie, setCookie, updateCookie };

export const urls = {
  memberLogin: '/api/auth/member/login',
  me: '/api/users/me',
  logout: '/api/auth/logout',
  autoLogIn: '/api/auth/member/auto-login'
};

export const memberLogin = (data, rememberMe) => {
  const loginData = {
    username: data.username,
    email: data.email,
    password: data.password,
    remember_me: rememberMe
  };

  return axios.post(urls.memberLogin, loginData, {
    headers: {
      doNotAddUserTokenAuthorization: true
    }
  });
};

export const authLogout = () => {
  return axios.post(urls.logout);
};

export const authMe = (accessToken) => {
  const loginData = {
    access_token: accessToken
  };
  const data = axios.get(urls.me, loginData, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  });
  // TODO CANARY SWITCH
  // const cookie = getCookie('platform-version');

  // data?.then((a) => {
  //   if (!cookie) {
  //     setCookie('platform-version', a.data.data.platform_version);
  //     location.reload();
  //   } else if (cookie !== a.data.data?.platform_version.toString()) {
  //     updateCookie('platform-version', a.data.data.platform_version, 30);
  //     location.reload();
  //   }
  // });
  return data;
};
export const autoLogIn = (id, hash, source) => {
  return axios.get(`${urls.autoLogIn}/${id}/${hash}?source=${source}`);
};
