export const formatCardNumber = (value: string): string => {
  // Remove all non-digits and limit to 16 characters
  const digits = value.replace(/\D/g, '').substring(0, 16);
  // Add space after every 4 digits
  return digits.replace(/(\d{4})(?=\d)/g, '$1 ').trim();
};

export const formatCVV = (value: string): string => {
  return value.replace(/\D/g, '').substring(0, 3);
};

export const formatZipCode = (value: string): string => {
  return value.replace(/\D/g, '').substring(0, 5);
};

export const formatExpiry = (month: string, year: string): string => {
  return month && year ? `${month}/${year}` : '';
};
