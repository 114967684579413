export class SessionStorage {
  static setItem(key: string, value?: string | null) {
    if (value) {
      sessionStorage.setItem(key, value);
    }
  }

  static getItem(key: string) {
    return sessionStorage.getItem(key);
  }

  static clear() {
    sessionStorage.clear();
  }

  static removeItem(key: string) {
    sessionStorage.removeItem(key);
  }
}

export const SessionStorageItems = {
  ConversationRestoreStatus: 'com.localflirt.conversationRestoreStatus'
};
