import './ContactUs.scss';

import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { yupResolver } from '@hookform/resolvers/yup';
import { notification } from 'antd';
import React, { memo, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
// import { ZendeskAPI } from 'react-zendesk';
import * as yup from 'yup';

import HEADERIMAGE from '../../../../../assets/images/HeadersImages/Contact-Header.png';
import Button from '../../../../../components/Button/Button';
import { Form } from '../../../../../components/Form/Form';
import Input from '../../../../../components/Form/Input/Input';
import HeaderLayout from '../../../../../components/HeaderLayout/HeaderLayout';
// import { setZendeskWidget } from '../../../../../redux/actions/zendesk';
import { showRootPage } from '../../../../../services/navigation-service/navigation.service';
import { getToken } from '../../../../../services/token-service/token.service';
import {
  requestCancelSubs,
  sumbitContactForm
} from '../../../../../services/user-service/user.service';
import { cleanString } from '../../../../../V2/helpers/cleanString';
import { useFreshChat } from '../../../../../V2/hooks/useFreshChat';
import { useFreshdeskScript } from '../../../../../V2/hooks/useFreshdeskScript';
import { FreshchatPlatform } from '../../../../../V2/model/enum/FreshchatPlatform.enum';

const schema = yup.object().shape({
  name: yup.string().required('Name is required'),
  email: yup.string().required('Email is required').email(),
  password: yup.string().required('Password is required'),
  subject: yup.string().required('Subject is required'),
  message: yup.string().required('Message is required')
});

const FreshDeskComponent = () => {
  const scriptUrl = 'https://widget.freshworks.com/widgets/9000000934.js';
  const windowId = '#freshdesk-widget';
  const [] = useFreshdeskScript(scriptUrl, windowId);
  return null;
};

const UserChat = memo(({ hasSpentBefore, freshchatPlatform }) => {
  const platform = cleanString(freshchatPlatform);
  if (hasSpentBefore && platform === FreshchatPlatform.CHAT) {
    return null;
  }
  return <FreshDeskComponent />;
});

const ContactUs = memo(() => {
  const userData = JSON.parse(localStorage.getItem('userData'));
  const hasSpentBefore = useMemo(
    () => userData?.has_spent_before ?? false,
    [userData?.has_spent_before]
  );
  const freshchatPlatform = useMemo(
    () => userData?.freshdesk_platform ?? FreshchatPlatform.WIDGET,
    [userData?.freshdesk_platform]
  );
  const token = getToken();
  const title = 'Contact Us';
  const description =
    "We're here to help. If your experience isn't going smoothly please let us know";
  const image = HEADERIMAGE;
  const { register, handleSubmit, getValues, setValue, control } = useForm({
    resolver: yupResolver(schema)
  });

  const dispatch = useDispatch();
  const zendesk = useSelector((state) => state.zendeskWidget);
  //for contact form
  const submitSucceeded = () => {
    notification.open({
      message:
        'Thank you. Your message has been received and a member of our customer support team will be in touch within 24 hours',
      placement: 'topRight',
      className: 'messagePopup',
      duration: 5,
      icon: <CheckCircleOutlined style={{ color: '#9f0220' }} />
    });

    setValue('name', '');
    setValue('emailContact', '');
    setValue('subject', '');
    setValue('text', '');
  };

  const submitFailed = (err) => {
    notification.open({
      message:
        'Ooops! Your message failed to send. Please, fill all the fields, check your email address and try again.',
      placement: 'topRight',
      className: 'messagePopup',
      duration: 5,
      icon: <CloseCircleOutlined style={{ color: 'red' }} />
    });
  };

  //for cancelation request
  const cancellationSucceeded = () => {
    notification.open({
      message:
        'Thank you. Your cancellation request has been received and a member of our customer support team will be in touch within 24 hours',
      placement: 'topRight',
      className: 'messagePopup',
      duration: 5,
      icon: <CheckCircleOutlined style={{ color: '#9f0220' }} />
    });

    setValue('email', '');
    setValue('password', '');
  };
  const cancellationFailed = (err) => {
    console.log(err);
    notification.open({
      message:
        'Ooops! Your cancellation request has been declined. Please, check your email address and password.',
      placement: 'topRight',
      className: 'messagePopup',
      duration: 5,
      icon: <CloseCircleOutlined style={{ color: 'red' }} />
    });
  };

  //for contact form
  const handleClickSubmit = () => {
    const data = {
      email: getValues('emailContact'),
      name: getValues('name'),
      subject: getValues('subject'),
      body: getValues('text')
    };

    sumbitContactForm(data, token)
      .then((res) => submitSucceeded())
      .catch((err) => submitFailed(err));
  };

  //for cancelation request
  const handleCancelSubscription = () => {
    const data = {
      email: getValues('email'),
      password: getValues('password')
    };
    requestCancelSubs(data, token)
      .then((data) => cancellationSucceeded())
      .catch((err) => cancellationFailed(err));
  };

  const ZENDESK_KEY = 'aee0ba86-fa16-4920-ab02-d2acaaccc3c6';

  const setting = {
    color: {
      theme: '#9f0220'
    },
    launcher: {
      chatLabel: {
        'en-US': 'Need Help'
      }
    }
  };

  // useEffect(() => {
  //   dispatch(setZendeskWidget(true));
  //   ZendeskAPI('webWidget', 'show');

  //   return () => {
  //     ZendeskAPI('webWidget', 'hide');
  //     dispatch(setZendeskWidget(false));
  //   };
  // }, [dispatch]);

  useFreshChat({ mode: 'auto', autoShowDelay: 5000 });

  return (
    <div className="ContactUs">
      <UserChat hasSpentBefore={hasSpentBefore} freshchatPlatform={freshchatPlatform} />
      <HeaderLayout
        title={title}
        description={description}
        image={image}
        className={'ContactUsImage'}
      />
      <div className="ContactUs-Container">
        <div className="ContactUs-Content">
          <p className="ContactUs-By">
            Contact us by phone at +1 877-381-0324 or email us at{' '}
            <span className="Support-Text" target="_blank" rel="noopener noreferrer">
              support@localflirt.com
            </span>
            . Our support team <br className="br" /> operates 24 hours a day, 7 days a week. All
            emails will be answered within 24 hours.
          </p>
        </div>

        <Form className="Message-Form" onSubmit={handleSubmit()}>
          <Input register={register} name="name" placeholder="name" />
          <Input register={register} name="emailContact" placeholder="email address" />
          <Input register={register} name="subject" placeholder="subject" />
          <Controller
            name="text"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <textarea className="Message-Area" placeholder="Message" {...field} />
            )}
          />
          {/* <textarea ref={register} name="text" className="Message-Area" placeholder="Message"/> */}
          <Button text="SUBMIT" onClick={handleClickSubmit} />
        </Form>

        <>
          <div className="Cancel-Subscription">
            <h2 className="Cancel-Subscription">Cancel Subscription</h2>
            <p className="Cancel-Subscription-text">
              You'll be missed! Please enter your email and password to proceed with cancellation.
            </p>
          </div>
          <Form className="Cancel-Subscription-Form">
            <Input register={register} name="email" placeholder="Your Registered Email Address" />
            <Input register={register} name="password" placeholder="Password" type="password" />
            {window.location.pathname === '/members-area/contact-us' ? (
              userData?.premium ? (
                <>
                  <Button onClick={handleCancelSubscription} text="CANCEL SUBSCRIPTION" />
                  <p className="CancelSubsText">For users with an active premium membership only</p>
                </>
              ) : (
                <>
                  <Button className="disabled" text="CANCEL SUBSCRIPTION" />
                  <p className="CancelSubsText">For users with an active premium membership only</p>
                </>
              )
            ) : (
              <>
                <Button onClick={() => showRootPage()} text="CANCEL SUBSCRIPTION" />
                <p className="CancelSubsText">For users with an active premium membership only</p>
              </>
            )}
          </Form>
        </>
      </div>
    </div>
  );
});

export default ContactUs;
