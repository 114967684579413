import { useMutation } from '@tanstack/react-query';
import { notification } from 'antd';

import { queryClient } from '../../App';
import { axiosInstance } from '../../axios/axiosInstance';
import { key as KeyInboxKeySingleConversation } from '../../core/messages/useGetConversationById';
import { key as KeyInbox } from '../../core/messages/useGetInbox';
import { key as LatestMessagesKey } from '../../core/messages/useGetLatestMessages';

export const key = ['GET_ARCHIVED_CONVERSATION'];

export const useGetArchivedConversation = () =>
  useMutation({
    mutationKey: [...key],
    mutationFn: async ({ profileId }) => {
      const { data } = await axiosInstance.get(
        `api/member/inbox/conversations/restore/${profileId}`
      );
      return data;
    },
    onSuccess: () => {
      notification.success({
        message: 'Success',
        description: 'Conversation successfuly restored'
      });
      queryClient.invalidateQueries({ queryKey: [...KeyInboxKeySingleConversation] });
      queryClient.invalidateQueries({ queryKey: [...KeyInbox] });
      queryClient.invalidateQueries({ queryKey: [...LatestMessagesKey] });
    },
    onError: (error) => {
      notification.error({
        message: 'Error',
        description:
          error?.response?.data?.message ??
          error?.message ??
          'Something went wrong. Please try again.'
      });
    }
  });
