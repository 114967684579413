let isScriptLoaded;

const importScript = () => {
  const scriptId = 'adn-script';

  const existingScript = document.getElementById(scriptId);

  if (existingScript) {
    existingScript.remove();
    isScriptLoaded = false;
  }

  const isMobile = window.innerWidth <= 1024;
  const popunderSiteId = isMobile ? 'lf_us_popunder_mob' : 'lf_us_popunder_desktop';

  const headNode = document.head;

  const adn = document.createElement('script');
  adn.type = 'text/javascript';
  adn.async = true;
  adn.id = scriptId;
  adn.src = `//a.7amz.com/oauth2?id=529&r=*${Math.floor(
    Math.random() * 99999
  )}&pu=0&tu=1&bs=0&s1=${popunderSiteId}&cs=0`;

  adn.onerror = () => {
    removePopUnderOverlay();
    isScriptLoaded = false;
  };

  adn.onload = () => {
    isScriptLoaded = true;
  };

  headNode.appendChild(adn);
};

const setPopUnderOverlay = () => {
  if (isScriptLoaded === false) {
    return;
  }

  const existingAnchor = document.getElementById('popunder-anchor');

  if (existingAnchor) {
    existingAnchor.href = window.location.href;
    return;
  }

  const anchor = document.createElement('a');
  anchor.id = 'popunder-anchor';
  anchor.href = window.location.href;

  // Apply overlay styles
  Object.assign(anchor.style, {
    position: 'absolute',
    top: '0',
    left: '0',
    width: '100%',
    height: '100vh',
    overflow: 'auto',
    opacity: '0',
    zIndex: '999'
  });

  // Append the anchor element to the main element
  document.querySelector('div.MainView').appendChild(anchor);
};

export const executePopUnder = () => {
  importScript();
  setPopUnderOverlay();
};

export const removeAdZoneWidget = () => {
  const adZoneWidget = document.getElementById('jerky-im');
  if (adZoneWidget) {
    adZoneWidget.style.opacity = 0;
    adZoneWidget.style.pointerEvents = 'none';
  }
};
export const showAdZoneWidget = () => {
  const adZoneWidget = document.getElementById('jerky-im');
  if (adZoneWidget) {
    adZoneWidget.style.opacity = 1;
    adZoneWidget.style.pointerEvents = 'all';
  }
};

export const getPopUnderExpiration = () => {
  const regexPattern = /^ADN-POP-(\d+-\d+)-expiry$/;

  // Iterate through localStorage keys
  for (const key of Object.keys(localStorage)) {
    if (regexPattern.test(key)) {
      const match = key.match(regexPattern);
      if (match) {
        const desiredPart = match[1];
        return localStorage.getItem(`ADN-POP-${desiredPart}-expiry`);
      }
    }
  }
  return null;
};

export const removePopUnderOverlay = () => {
  var elementToRemove = document.getElementById('popunder-anchor');

  if (elementToRemove) {
    elementToRemove.parentNode.removeChild(elementToRemove);
  }
};

export const updatePopUnderHref = () => {
  const existingAnchor = document.getElementById('popunder-anchor');

  if (existingAnchor) {
    existingAnchor.href = window.location.href;
  }
};
