import { useMutation } from '@tanstack/react-query';

import { axiosInstance } from '../../../axios/axiosInstance';

export const key = ['SMS_VERIFICATION'];

export const useVerifyUserPhoneNumber = () => {
  return useMutation({
    mutationKey: [...key],
    mutationFn: async (phoneNumber: string) => {
      const { data } = await axiosInstance.post('api/auth/member/sms-verification/send', {
        phone_number: phoneNumber
      });
      return data;
    }
  });
};
