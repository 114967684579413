import './style.scss';

import { LoadingOutlined } from '@ant-design/icons';
import { Modal, Select, Spin } from 'antd';
import { useEffect, useState } from 'react';

import { sentenceCase } from '../../../util/sentenceCase';
import { useGetAllPackages } from '../../services/packages/useGetAllPackages';

const { Option } = Select;

export const ContinueChattingV2 = ({
  open,
  onCancel,
  handleSend,
  isLoading,
  profileId,
  packageId
}: {
  open: boolean;
  onCancel: () => void;
  handleSend: (selectedPackageId: number) => void;
  isLoading: boolean;
  profileId: number;
  packageId: number;
}) => {
  const { data: packages } = useGetAllPackages(Boolean(profileId));
  const [selectedPackage, setSelectedPackage] = useState<number>(packageId ?? packages?.[0]?.id);

  const style: React.CSSProperties = {
    display: 'flex',
    justifyContent: 'center',
    padding: '20px 0',
    gap: 20,
    flexDirection: 'column',
    alignItems: 'center'
  };

  useEffect(() => {
    if (open) {
      sessionStorage.setItem('continueChatModalOpen', 'true');
    }

    const shouldClose = sessionStorage.getItem('closeAfterPurchase') === 'true';
    if (shouldClose && open) {
      sessionStorage.removeItem('closeAfterPurchase');
      onCancel();
    }
  }, [open, onCancel]);

  const handleSelectPackage = (value: number) => {
    setSelectedPackage(value);
  };

  return (
    <div>
      <Modal
        width={'45%'}
        className={'payment-experiment-modal payment-experiment-modal-continue-chatting-v2'}
        footer={null}
        bodyStyle={style}
        maskClosable={!isLoading}
        mask
        open={open}
        onCancel={() => {
          if (!isLoading) {
            sessionStorage.removeItem('continueChatModalOpen');
            onCancel();
          }
        }}
        closable={!isLoading}
        zIndex={999}
      >
        {isLoading ? (
          <div className="loading-overlay">
            <Spin indicator={<LoadingOutlined style={{ fontSize: 36, color: '#9f0220' }} spin />} />
            <p>
              Please wait while we process your payment.
              <br />
              Do not close this page.
            </p>
          </div>
        ) : (
          <>
            <h2>
              Oops! You've run out of credits.
              <br />
              <span className="payment-experiment-modal-continue-chatting-v2-text-highlight">
                Get more credits
              </span>{' '}
              to keep flirting.
            </h2>
            <Select
              style={{ width: '100%', maxWidth: '350px' }}
              placeholder="Choose credit bundle"
              optionLabelProp="label"
              value={selectedPackage}
              onChange={handleSelectPackage}
              labelInValue={false}
              popupClassName="payment-experiment-modal-select-dropdown"
            >
              {packages?.map((pkg) => {
                const text = `${sentenceCase(pkg.name)} - ${pkg.number_of_messages} Messages ($${
                  pkg.price_per_message
                }/message)`;
                return (
                  <Option key={pkg.id} value={pkg.id} label={text}>
                    {text}
                  </Option>
                );
              })}
            </Select>
            <button
              className={'payment-experiment-modal-button'}
              onClick={() => handleSend(selectedPackage)}
            >
              Send Message
            </button>
          </>
        )}
      </Modal>
    </div>
  );
};
