import { notification } from 'antd';
import axios from 'axios';
import Echo from 'laravel-echo';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { queryClient } from '../App';
import { key as SingleConversationKey } from '../core/messages/useGetConversationById';
import { key as inboxKey } from '../core/messages/useGetInbox';
import { key as LatestMessagesKey } from '../core/messages/useGetLatestMessages';
import { setNewConversationStatus } from '../redux/actions/messageConversationStatus';
import { CURRENT_USER_CREDITS } from '../redux/types/credits';
import { SET_NEW_MESSAGE } from '../redux/types/newMessages';
import { SET_NOTIFICATIONS, UPDATE_NOTIFICATIONS } from '../redux/types/notifications';
import { authMe } from '../services/auth-service/auth.service';
import { getToken } from '../services/token-service/token.service';
import { getUserData, setUserLocalData } from '../services/user-service/user.service';
import { SessionStorage, SessionStorageItems } from '../V2/helpers/SessionStorage';
import { useNotificationSound } from '../V2/hooks/useNotificationSound';
import { ContainsArchivedConversationsEnum } from '../V2/model/enum/ContainsArchivedConversationsEnum.enum';

export const useWebSocket = () => {
  const token = getToken();
  const userData = getUserData();
  const dispatch = useDispatch();
  let { notifications } = useSelector((state) => state);
  const play = useNotificationSound();

  // console.log(messageNotifications);

  const options = useMemo(
    () => ({
      broadcaster: 'pusher',
      key: process.env.REACT_APP_PUSHER_APP_KEY,
      cluster: process.env.REACT_APP_PUSHER_APP_CLUSTER,
      forceTLS: false,
      wsHost: process.env.REACT_APP_PUSHER_WS_HOST,
      wssHost: process.env.REACT_APP_PUSHER_WSS_HOST,
      wsPort: process.env.REACT_APP_PUSHER_PORT,
      wssPort: process.env.REACT_APP_PUSHER_WSS_PORT,
      enabledTransports: ['ws', 'wss'],
      authEndpoint: process.env.REACT_APP_PUSHER_AUTH_ENDPOINT,
      auth: {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: 'application/json'
        }
      },
      authorizer: (channel, options) => {
        return {
          authorize: (socketId, callback) => {
            axios
              .post(process.env.REACT_APP_PUSHER_AUTH_ENDPOINT, {
                socket_id: socketId,
                channel_name: channel.name
              })
              .then((response) => {
                callback(false, response.data);
              })
              .catch((error) => {
                console.log(error);
                callback(true, error);
              });
          }
        };
      },
      // encrypted: true,
      transports: ['websocket', 'polling', 'flashsocket'],
      disableStats: true
    }),
    [token]
  );

  const echo = useMemo(() => new Echo(options), [options]);

  useEffect(() => {
    if (echo) {
      (() => {
        try {
          echo
            .private(`users.${userData?.id}`)
            .listen('.successful.payment', (data) => {
              authMe(token)
                .then((res) => {
                  dispatch({
                    type: CURRENT_USER_CREDITS,
                    payload: res.data.data.credits
                  });
                  setUserLocalData(res.data);
                })
                .catch((err) => console.log(err));
            })
            .listen('.successful.automatic.payment', (data) => {
              notification.success({
                message: data?.message ?? 'You have successfully purchased credits!'
              });
            })
            .listen('.conversations.restored', () => {
              SessionStorage.setItem(
                SessionStorageItems.ConversationRestoreStatus,
                ContainsArchivedConversationsEnum.COMPLETED
              );
              notification.success({
                key: 'allConversationsRestored',
                message: 'Success',
                description: 'All conversations have been restored successfully!'
              });
              queryClient.invalidateQueries({ queryKey: [...inboxKey] });
              queryClient.invalidateQueries({ queryKey: [...LatestMessagesKey] });
              queryClient.invalidateQueries({ queryKey: [...SingleConversationKey] });
              queryClient.invalidateQueries('ME');
            })
            .listen('.message.received', (data) => {
              const messageData = { ...data.message, popUp: true };
              queryClient.invalidateQueries('INBOX');
              queryClient.invalidateQueries('LATEST');
              dispatch({ type: SET_NEW_MESSAGE, payload: messageData });
              dispatch(setNewConversationStatus(true));
              // if (window.location.pathname !== "/members-area/messages") {
              //   dispatch({ type: SET_NEW_MESSAGE, payload: messageData });
              // } else {
              //   dispatch({
              //     type: SET_NEW_MESSAGE,
              //     payload: { ...messageData, popUp: false },
              //   });
              // }

              const dataFromLocalStorage = JSON.parse(localStorage.getItem('userData'));

              const messageNotifications =
                Number(dataFromLocalStorage?.unread_message_notifications) + 1;
              dispatch({
                type: UPDATE_NOTIFICATIONS,
                payload: messageNotifications
              });
              // console.log(data.message);

              const newDataFromLocalStorage = {
                ...dataFromLocalStorage,
                unread_message_notifications: messageNotifications
              };

              localStorage.setItem('userData', JSON.stringify(newDataFromLocalStorage));

              // const messageNotifications = newNotifications.filter(
              //   (note) => note.type === "message"
              // );
              messageNotifications &&
                dispatch({
                  type: SET_NOTIFICATIONS,
                  payload: Number(messageNotifications) || ''
                });
              play();

              //  authMe().then(res => setUserLocalData(res))
            });
        } catch (e) {
          console.log(e);
        }
      })();
    }
    return () => {
      // console.log('nesto');
      echo.leave(`users.${userData?.id}`);
    };
  }, [dispatch, echo, userData?.id]);

  // useEffect(() => {
  //   if (echo) {
  //     (() => {
  //       try {
  //         echo
  //           .private(`users.${userData?.id}`)
  //           .listen(".payment.processed", data => {
  //             console.log(data);
  //             dispatch({
  //               type: SET_PAYMENT_NOTIFICATION,
  //               payload: data.status
  //             });
  //           });
  //       } catch (e) {
  //         console.log(e);
  //       }
  //     })();
  //   }
  //   return () => {
  //     console.log("nesto");
  //     echo.leave(`users.${userData?.id}`);
  //   };
  // }, [dispatch, echo, userData?.id]);
};
