import { useMutation } from '@tanstack/react-query';

import { queryClient } from '../../App';
import { axiosInstance } from '../../axios/axiosInstance';
import { ViewFromEnum } from '../../V2/model/enum/ViewFromEnum.enum';
import { key as InboxKey } from './useGetInbox';
import { key as LatestKey } from './useGetLatestMessages';

const url = 'api/member/inbox/conversations/';
export const useReadMessage = () =>
  useMutation(
    [],
    async ({
      userId,
      id,
      view_from
    }: {
      userId: number;
      id: unknown;
      view_from: ViewFromEnum;
    }): Promise<void> => {
      id &&
        (await axiosInstance.get(`${url}${userId}/${id as string}/read`, {
          params: {
            view_from
          }
        }));
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([...InboxKey]);
        queryClient.invalidateQueries([...LatestKey]);
      }
    }
  );
