import { useMemo } from 'react';

import { shouldShowElement } from '../../util/banners';
import { useDeviceType } from '../useDeviceType';

export const useAdZoneEnabled = (settings) => {
  const deviceType = useDeviceType();

  const isAdzoneHeaderEnabled = useMemo(
    () =>
      shouldShowElement({
        device: deviceType,
        showBannerMobile: settings?.headerMobileActive,
        showBannerDesktop: settings?.headerWebActive
      }),
    [deviceType, settings]
  );

  const isAdzoneFooterEnabled = useMemo(
    () =>
      shouldShowElement({
        device: deviceType,
        showBannerMobile: settings?.footerMobileActive,
        showBannerDesktop: settings?.footerWebActive
      }),
    [deviceType, settings]
  );

  return {
    isAdzoneHeaderEnabled,
    isAdzoneFooterEnabled
  };
};
