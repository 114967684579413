import { useEffect } from 'react';

const useInterstitialAdZone = (shouldAppendScript) => {
  useEffect(() => {
    const isMobile = window.innerWidth <= 1024;
    const interstitialSiteId = isMobile ? 'lf_us_interstitial_mob' : 'lf_us_interstitial_desktop';

    const scriptId = 'adn-1031';

    const appendScript = () => {
      if (document.getElementById(scriptId)) return; // Avoid duplicates
      const script = document.createElement('script');
      script.id = scriptId;
      script.type = 'text/javascript';
      script.async = true;
      script.textContent = `
        !function(s,o,e,c,t,a,r,l){
          o.ADNPageTracker=o.ADNPageTracker||{};
          var n=a;
          const m={
            key:"ADN-PT-"+e,
            get(){
              try{
                var e=o.localStorage.getItem(this.key),t;
                return e?(t=JSON.parse(e),(new Date).getTime()>t.expiryTime?(this.remove(),null):t):null
              }catch(e){return null}
            },
            set(e){
              try{
                var t;
                e.expiryTime||(t=new Date,e.expiryTime=t.getTime()+3600*c*1e3),
                o.localStorage.setItem(this.key,JSON.stringify(e))
              }catch(e){}
            },
            remove(){
              try{o.localStorage.removeItem(this.key)}catch(e){}
            }
          };
          function i(e){
            !1===c&&(c=48);
            var t=s.referrer,a=o.location,r=a.host;
            let n=e||a.pathname;
            e||(n+=a.search);
            var e=m.get()||{last:"",fired:!1,loads:0,expiryTime:null},i;
            n!==e.last&&(!Object.keys(e).length||t&&new URL(t).host!==r||e.fired&&2<=e.loads?m.set({last:n,fired:!1,loads:1}):(e.loads++,e.last=n,2<=e.loads&&!e.fired?(e.fired=!0,m.set(e),l()):m.set(e)))
          }
          o.ADNPageTracker.execute=i;
          o.triggerInterstitial=i;
          i();
          o.addEventListener("popstate",function(){i()});
        }(document, window, 1031, false, 2, false, true, function() {
          (function(node) {
            const script = document.createElement("script");
            script.type = "text/javascript";
            script.async = true;
            script.src = "//a.7amz.com/posty?id=1031&r=" + Math.floor(Math.random() * 99999) + "&acd=99999&s1=${interstitialSiteId}";
            node.appendChild(script);
          })(document.getElementsByTagName("script")[document.getElementsByTagName("script").length - 1].parentNode);
        });
      `;
      document.body.appendChild(script);
    };

    if (shouldAppendScript) {
      appendScript();
    } else {
      const existingScript = document.getElementById(scriptId);
      if (existingScript) {
        existingScript.remove();
      }
    }

    return () => {
      const existingScript = document.getElementById(scriptId);
      if (existingScript) {
        existingScript.remove();
      }
    };
  }, [shouldAppendScript]);
};

export default useInterstitialAdZone;
