import { useRecoilState } from 'recoil';

import { userData as userRecoil } from '../../../../recoil/atoms/userDataAtom';
import { CreditsPackage } from '../../../model/classes/CreditsPackage';
import { SplitTest } from '../../../model/enum/SplitTest.enum';
import { CreditsCardV1 } from './CreditsCardV1';
import { CreditsCardV2 } from './CreditsCardV2';

interface CreditsCardProps {
  packageDef: CreditsPackage;
  redirectUrl?: string;
  onSuccess?: () => void;
  trigger?: 'credits_page' | 'credits_modal';
  noBuy?: boolean;
  profileId?: number;
  lastMessageId?: number;
  version?: number;
  starterPackage?: boolean;
  activeCardId?: string | null;
  setActiveCardId?: (id: string | null) => void;
  ignoreFlipTest?: boolean;
}

export const CreditsCard = ({
  packageDef,
  redirectUrl,
  onSuccess,
  trigger,
  noBuy,
  profileId,
  lastMessageId,
  version,
  starterPackage,
  activeCardId,
  setActiveCardId,
  ignoreFlipTest = false
}: CreditsCardProps) => {
  const [user] = useRecoilState(userRecoil);
  const creditCardVersion = version || user?.data?.package_layout;

  const flipTestEligibleUser =
    !ignoreFlipTest &&
    user?.data?.split_tests.includes(SplitTest.FLIP_TEST_ELIGIBLE_USERS) &&
    (user?.data?.user_has_card_removed || !user?.data?.has_spent_before);

  const cardId = `card-${packageDef.id}`;

  const cardProps = {
    packageDef,
    redirectUrl,
    onSuccess,
    trigger,
    noBuy,
    profileId,
    lastMessageId,
    starterPackage,
    isActive: activeCardId === cardId,
    onFlip: () => {
      if (flipTestEligibleUser) {
        if (activeCardId === cardId) {
          setActiveCardId?.(null);
        } else {
          setActiveCardId?.(cardId);
        }
      }
    },
    isTestEligible: flipTestEligibleUser
  };

  return creditCardVersion === 1 ? (
    <CreditsCardV1 {...cardProps} />
  ) : (
    <CreditsCardV2 {...cardProps} />
  );
};
