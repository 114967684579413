import { useMutation } from '@tanstack/react-query';
import { notification } from 'antd';

import { axiosInstance } from '../../axios/axiosInstance';
import { SessionStorage, SessionStorageItems } from '../../V2/helpers/SessionStorage';
import { ContainsArchivedConversationsEnum } from '../../V2/model/enum/ContainsArchivedConversationsEnum.enum';

export const key = ['GET_ARCHIVED_CONVERSATIONS'];

export const useGetArchivedConversations = () => {
  return useMutation({
    mutationKey: [...key],
    mutationFn: async () => {
      const { data } = await axiosInstance.get('api/member/inbox/conversations/restore');
      return data;
    },
    onSuccess: async (response) => {
      notification.success({
        message: 'Restoring',
        description: response?.message
      });
      SessionStorage.setItem(
        SessionStorageItems.ConversationRestoreStatus,
        ContainsArchivedConversationsEnum.RESTORING
      );
    },
    onError: (error) => {
      notification.error({
        message: 'Error',
        description:
          error?.response?.data?.message ??
          error?.message ??
          'Somethning went wrong. Please try again.'
      });
    }
  });
};
