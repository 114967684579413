// Tracks whether the ad zones are already loaded
let areAdZonesLoaded = false;
let showPopunder = false;

const AD_ZONE_URL_FOOTER_SM = '//a.7amz.com/loader?a=1036&s=135&t=21&p=242';
const AD_ZONE_URL_FOOTER_LG = '//a.7amz.com/loader?a=1038&s=135&t=20&p=242';
const AD_ZONE_URL_HEADER_SM = '//a.7amz.com/loader?a=1035&s=135&t=22&p=242';
const AD_ZONE_URL_HEADER_LG = '//a.7amz.com/loader?a=1037&s=135&t=20&p=242';

export const AD_ZONE_CONFIGS = {
  '/members-area': {
    header: {
      containerSelector: '#header-adzone-container',
      smallScreenSrc: `${AD_ZONE_URL_HEADER_SM}&s1=lf_us_header_browse_profiles_mob`,
      largeScreenSrc: `${AD_ZONE_URL_HEADER_LG}&s1=lf_us_header_browse_profiles_desktop`
    },
    footer: {
      containerSelector: '#footer-adzone-container',
      smallScreenSrc: `${AD_ZONE_URL_FOOTER_SM}&s1=lf_us_footer_browse_profiles_mob`,
      largeScreenSrc: `${AD_ZONE_URL_FOOTER_LG}&s1=lf_us_footer_browse_profiles_desktop`
    }
  },
  '/members-area/my-matches': {
    header: {
      containerSelector: '#header-adzone-container-my-matches',
      smallScreenSrc: `${AD_ZONE_URL_HEADER_SM}&s1=lf_us_header_my_matches_mob`,
      largeScreenSrc: `${AD_ZONE_URL_HEADER_LG}&s1=lf_us_header_my_matches_desktop`
    },
    footer: {
      containerSelector: '#footer-adzone-container-my-matches',
      smallScreenSrc: `${AD_ZONE_URL_FOOTER_SM}&s1=lf_us_footer_my_matches_mob`,
      largeScreenSrc: `${AD_ZONE_URL_FOOTER_LG}&s1=lf_us_footer_my_matches_desktop`
    }
  },
  '/members-area/my-favorites': {
    header: {
      containerSelector: '#header-adzone-container-my-favorites',
      smallScreenSrc: `${AD_ZONE_URL_HEADER_SM}&s1=lf_us_header_favorites_mob`,
      largeScreenSrc: `${AD_ZONE_URL_HEADER_LG}&s1=lf_us_header_favorites_desktop`
    },
    footer: {
      containerSelector: '#footer-adzone-container-my-favorites',
      smallScreenSrc: `${AD_ZONE_URL_FOOTER_SM}&s1=lf_us_footer_favorites_mob`,
      largeScreenSrc: `${AD_ZONE_URL_FOOTER_LG}&s1=lf_us_footer_favorites_desktop`
    }
  },
  '/members-area/visitors': {
    header: {
      containerSelector: '#header-adzone-container-visitors',
      smallScreenSrc: `${AD_ZONE_URL_HEADER_SM}&s1=lf_us_header_visitors_mob`,
      largeScreenSrc: `${AD_ZONE_URL_HEADER_LG}&s1=lf_us_header_visitors_desktop`
    },
    footer: {
      containerSelector: '#footer-adzone-container-visitors',
      smallScreenSrc: `${AD_ZONE_URL_FOOTER_SM}&s1=lf_us_footer_visitors_mob`,
      largeScreenSrc: `${AD_ZONE_URL_FOOTER_LG}&s1=lf_us_footer_visitors_desktop`
    }
  },
  '/members-area/match-game': {
    header: {
      containerSelector: '#header-adzone-container-match-game',
      smallScreenSrc: `${AD_ZONE_URL_HEADER_SM}&s1=lf_us_header_match_game_mob`,
      largeScreenSrc: `${AD_ZONE_URL_HEADER_LG}&s1=lf_us_header_match_game_desktop`
    },
    footer: {
      containerSelector: '#footer-adzone-container-match-game',
      smallScreenSrc: `${AD_ZONE_URL_FOOTER_SM}&s1=lf_us_footer_match_game_mob`,
      largeScreenSrc: `${AD_ZONE_URL_FOOTER_LG}&s1=lf_us_footer_match_game_desktop`
    }
  }
};

const DEFAULT_DIMENSIONS = {
  HEADER: {
    smallWidth: 300,
    smallHeight: 100,
    largeWidth: 728,
    largeHeight: 90
  },
  FOOTER: {
    smallWidth: 300,
    smallHeight: 250,
    largeWidth: 728,
    largeHeight: 90
  }
};

/**
 * Appends the ad zone iframe to the body for the specified ad type (header or footer).
 * @param {string} adType - The type of ad zone ("header" or "footer").
 * @param {string} containerSelector - The selector of the container element.
 * @param {string} smallScreenSrc - The iframe source URL for small screens.
 * @param {string} largeScreenSrc - The iframe source URL for large screens.
 * @param {object} dimensions - Object containing width and height for small and large screens.
 */
const appendAdZone = (adType, containerSelector, smallScreenSrc, largeScreenSrc, dimensions) => {
  const adZoneId = `${adType}-adzone`;
  const existingIframe = document.getElementById(adZoneId);

  // Prevent duplicate loading
  if (existingIframe) {
    return;
  }

  const isSmallScreen = window.innerWidth <= 850;
  const iframeSrc = isSmallScreen ? smallScreenSrc : largeScreenSrc;
  const { smallWidth, smallHeight, largeWidth, largeHeight } = dimensions;

  const iframe = createAdZoneIframe(
    adZoneId,
    iframeSrc,
    isSmallScreen ? smallWidth : largeWidth,
    isSmallScreen ? smallHeight : largeHeight
  );

  document.body.appendChild(iframe);
  moveAdZoneToContainer(`#${adZoneId}`, containerSelector);
};

/**
 * Creates the ad zone iframe with the given parameters.
 * @param {string} id - The ID of the iframe element.
 * @param {string} src - The source URL for the iframe.
 * @param {number} width - The width of the iframe.
 * @param {number} height - The height of the iframe.
 * @returns {HTMLElement} The created iframe element.
 */
const createAdZoneIframe = (id, src, width, height) => {
  const iframe = document.createElement('iframe');
  iframe.id = id;
  iframe.src = src;
  iframe.setAttribute('data-zone', id.includes('header') ? '1035' : '1036');
  iframe.setAttribute('sandbox', 'allow-popups allow-scripts allow-same-origin allow-forms');
  iframe.style.cssText = 'border: 0; display: block;';
  iframe.width = width;
  iframe.height = height;
  iframe.scrolling = 'no';
  iframe.frameBorder = '0';
  return iframe;
};

/**
 * Moves the ad zone iframe to the designated container.
 * @param {string} iframeSelector - The selector of the iframe element.
 * @param {string} containerSelector - The selector of the container element.
 */
const moveAdZoneToContainer = (iframeSelector, containerSelector) => {
  const iframeEl = document.querySelector(iframeSelector);
  const container = document.querySelector(containerSelector);

  if (iframeEl && container) {
    iframeEl.remove(); // Remove the iframe from its current parent
    container.appendChild(iframeEl); // Append iframe to the new container
  }

  if (!container) {
    iframeEl.remove();
  }
};

export const setShowPopupState = (state) => {
  showPopunder = state;
};

/**
 * Appends header and footer ad zones based on screen size.
 */
export const appendAdZones = () => {
  const currentPath = window?.location?.pathname;
  if (!AD_ZONE_CONFIGS[currentPath] || areAdZonesLoaded || !showPopunder) {
    return;
  }

  const config = AD_ZONE_CONFIGS[currentPath];

  try {
    appendAdZone(
      'header',
      config.header.containerSelector,
      config.header.smallScreenSrc,
      config.header.largeScreenSrc,
      DEFAULT_DIMENSIONS.HEADER
    );

    appendAdZone(
      'footer',
      config.footer.containerSelector,
      config.footer.smallScreenSrc,
      config.footer.largeScreenSrc,
      DEFAULT_DIMENSIONS.FOOTER
    );

    areAdZonesLoaded = true;
  } catch (error) {
    console.error('Error appending ad zones:', error);
    areAdZonesLoaded = false;
  }
};

/**
 * Removes all ad zones from the page.
 */
export const removeAdZones = () => {
  ['header-adzone', 'footer-adzone'].forEach((id) => {
    const adZone = document.getElementById(id);
    if (adZone) {
      adZone.remove();
    }
  });

  areAdZonesLoaded = false; // Reset the loaded state
};

// Attach event listeners to handle page load and resize events.
window.addEventListener('load', appendAdZones);
window.addEventListener('resize', () => {
  removeAdZones();
  appendAdZones();
});
