import './creditsPage.scss';

import { useLocation } from '@reach/router';
import { Divider, Typography } from 'antd';
import { useState } from 'react';
import { useRecoilState } from 'recoil';

import { userData as userRecoil } from '../../../recoil/atoms/userDataAtom';
import { Page } from '../../components/layout/Page';
import { CreditsCard } from '../../components/shared/CreditsCard/CreditsCard';
import { useGetAllPackages } from '../../services/packages/useGetAllPackages';

const { Title } = Typography;
export const CreditsPage = () => {
  const curLocation = useLocation();
  const { data } = useGetAllPackages();
  const [user] = useRecoilState(userRecoil);
  const [activeCardId, setActiveCardId] = useState<string | null>(null);

  const renderPackages = (isSpecial: PhpBool) => {
    return data
      ?.sort((a, b) => b.starter - a.starter)
      ?.filter((item) => item.is_special_offer === isSpecial)
      .map((pkg) => (
        <CreditsCard
          redirectUrl={curLocation.href}
          packageDef={pkg}
          key={pkg.id}
          trigger={'credits_page'}
          activeCardId={activeCardId}
          setActiveCardId={setActiveCardId}
          ignoreFlipTest
        />
      ));
  };

  return (
    <Page>
      <Title className={'page-header'} level={2}>
        Purchase credits to send messages
      </Title>

      <div
        className={'balance'}
        style={{
          backgroundColor: user?.data?.credits > 10 ? '#EDEDED' : '#F5DDDD'
        }}
      >
        <div
          className={'amount'}
          style={{
            color: user?.data?.credits > 10 ? '#010101' : '#D41411'
          }}
        >
          {user?.data?.credits}
        </div>
        <div className={'note'}>credits balance</div>
      </div>
      {user?.data?.credits <= 10 ? <div className={'top-up'}>Top up your credits</div> : null}
      {Number(renderPackages(1)?.length) > 0 ? (
        <>
          <Divider dashed style={{ margin: 20 }} />
          <div className={'credits-page'}>{renderPackages(1)}</div>
        </>
      ) : null}
      <Divider dashed style={{ margin: 20 }} />
      <div className={'credits-page'}>{renderPackages(0)}</div>
    </Page>
  );
};
