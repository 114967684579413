export const LoadInboxIcon = () => {
  return (
    <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Group 39519">
        <g id="Group 39512">
          <rect
            id="Rectangle 806"
            x="0.5"
            y="0.5"
            width="14"
            height="14"
            rx="1.5"
            fill="#BE4D53"
            stroke="#BE4D53"
          />
        </g>
        <g id="Group 39519_2">
          <path
            id="Vector"
            d="M8.05064 11.315C7.86158 11.315 7.70815 11.4685 7.70815 11.6575C7.70815 11.8466 7.86158 12 8.05064 12C10.5128 12 12.5163 9.99679 12.5163 7.5343C12.5163 5.07181 10.5131 3.0686 8.05064 3.0686C5.93303 3.0686 4.15861 4.55192 3.70241 6.53287H3.26882C3.13046 6.53287 3.00545 6.61643 2.95236 6.74418C2.89928 6.87227 2.92873 7.01954 3.02668 7.11749L3.68529 7.7761C3.7168 7.80761 3.75481 7.83261 3.7966 7.85008C3.83838 7.86754 3.8829 7.87645 3.92743 7.87645C3.97195 7.87645 4.01647 7.8672 4.05826 7.85008C4.10004 7.83261 4.13806 7.80761 4.16957 7.7761L4.82817 7.11749C4.92612 7.01954 4.95557 6.87227 4.90249 6.74418C4.8494 6.61609 4.7244 6.53287 4.58603 6.53287H4.40862C4.84906 4.93345 6.31285 3.75358 8.05029 3.75358C10.135 3.75358 11.831 5.44958 11.831 7.5343C11.831 9.61903 10.135 11.315 8.05029 11.315H8.05064Z"
            fill="white"
            stroke="white"
            strokeWidth="0.3"
          />
          <path
            id="Vector_2"
            d="M5.13502 10.7666C5.31017 10.7666 5.45216 10.6246 5.45216 10.4495C5.45216 10.2743 5.31017 10.1323 5.13502 10.1323C4.95986 10.1323 4.81787 10.2743 4.81787 10.4495C4.81787 10.6246 4.95986 10.7666 5.13502 10.7666Z"
            fill="white"
            stroke="white"
            strokeWidth="0.2"
          />
          <path
            id="Vector_3"
            d="M6.10376 11.1822C6.03321 11.3425 6.1065 11.5298 6.26679 11.6C6.42707 11.6702 6.61441 11.5973 6.68462 11.437C6.75518 11.2767 6.68188 11.0894 6.5216 11.0191C6.36131 10.9489 6.17397 11.0219 6.10376 11.1822Z"
            fill="white"
            stroke="white"
            strokeWidth="0.2"
          />
          <path
            id="Vector_4"
            d="M4.09455 8.73687C3.93118 8.80057 3.8507 8.98448 3.9144 9.14751C3.9781 9.31053 4.16202 9.39136 4.32504 9.32766C4.48841 9.26395 4.56889 9.08004 4.50519 8.91701C4.44149 8.75399 4.25757 8.67316 4.09455 8.73687Z"
            fill="white"
            stroke="white"
            strokeWidth="0.2"
          />
        </g>
      </g>
    </svg>
  );
};
